import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import ApiPath from "../../../utils/api";
import { useDataContext } from "../../../context/Context";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

function AddNewBankForm({ data }) {
  const Navigate = useNavigate();
  const { PaymentMode, getBankTransfer } = useDataContext();
  const [formData, setFormData] = useState({
    mode: "",
    accountName: "",
    bankName: "",
    accountNumber: "",
    ibanNumber: "",
    narration: "",
    balance: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (data) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data,
      }));
    }
  }, [data]);

  const bankdata = [
    { mode: "Bank Account" },
    { mode: "Exchange" },
    { mode: "Mobile Money" },
    { mode: "Payment Link" },
    { mode: "Cash" },
    { mode: "Other" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await ApiPath.post("/bank", formData);
      message.success("Bank saved");
      Navigate("/banks/bankList");
    } catch (error) {
      console.error("Error submitting invoice:", error);
      message.success("Failed to submit Bank.");
    }
  };
  const updateBank = async () => {
    // e.preventDefault();
    try {
      const response = await ApiPath.put(`/bank/${formData._id}`, formData);
      console.log("Invoice saved:", formData._id);
      message.success("Bank updated!");
      window.location.reload();
    } catch (error) {
      // console.error("Error submitting invoice:", error);
      message.error("Failed to submit Bank.");
    }
  };

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit}
      style={{ background: "white", padding: 30 }}
    >
      <Container>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          className="smallHeading"
        >
          ADD ACCOUNT
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ marginBottom: "8px" }}>
              <Typography variant="h6" gutterBottom>
                Payment Information
              </Typography>

              <TextField
                select
                label="Payment Mode"
                name="mode"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.mode}
                onChange={handleChange}
                style={{ textTransform: "capitalize" }}
              >
                {bankdata.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.mode}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.mode}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Account Name"
                name="accountName"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.accountName}
                onChange={handleChange}
              />
              <TextField
                label="Bank Name"
                name="bankName"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.bankName}
                onChange={handleChange}
              />
              <TextField
                label="Account Number"
                name="accountNumber"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.accountNumber}
                onChange={handleChange}
              />
              <TextField
                label="IBAN Number"
                name="ibanNumber"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.ibanNumber}
                onChange={handleChange}
              />
              <TextField
                label="Opening Balance"
                name="balance"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.balance}
                onChange={handleChange}
              />
              <TextField
                label="Narration"
                name="narration"
                fullWidth
                margin="normal"
                variant="outlined"
                value={formData.narration}
                onChange={handleChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                padding: 2,
                border: "1px solid #ccc",
                borderRadius: "5px",
                overflowY: "auto",
                marginTop: "5.5vh",
              }}
            >
              <Typography variant="body1" gutterBottom>
                Payment Mode: {formData.mode}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Account Name: {formData.accountName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Bank Name: {formData.bankName}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Account Number: {formData.accountNumber}
              </Typography>
              <Typography variant="body1" gutterBottom>
                IBAN Number: {formData.ibanNumber}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Narration: {formData.narration}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box
          align="center"
          sx={{ width: "100%", margin: "auto", marginTop: "20px" }}
        >
          {data ? (
            <Button
              onClick={() => updateBank()}
              variant="contained"
              sx={{
                width: "30%",
                margin: "auto",
                marginTop: "20px",
                background: "brown",
              }}
            >
              UPDATE
            </Button>
          ) : (
            <Button
              type="submit"
              variant="contained"
              sx={{ width: "30%", margin: "auto", marginTop: "20px" }}
            >
              SAVE
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default AddNewBankForm;
