import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom";
import ApiPath from "../../../utils/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDataContext } from "../../../context/Context";
import { message } from "antd";
import { LogoImage, StampImage } from "../../../lib/data";
const ViewRefund = ({ data }) => {
  const {
    bankTransferData,
    customer: customerData,
    PaymentMode,
    getBankTransfer,
    itemList,
    userList,
    fetchAllData,
  } = useDataContext();
  const { Id } = useParams(); // Extracts the 'Id' parameter from the URL
  let invoiceData = data || {}; // Fallback to an empty object if data is undefined
  const [invoice, setInvoice] = useState(invoiceData);
  const [selectedModes, setSelectedModes] = useState([]); // State to store selected payment modes
  const [amounts, setAmounts] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({}); // State
  const [refund, setRefund] = useState(false); // State
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const getInvoiceData = async () => {
      try {
        const response = await ApiPath.get(`/paymentRecieved/refund/${Id}`);
        setInvoice(response.data);
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    getInvoiceData();
  }, [Id]); // Ensure data is fetched when 'Id' changes

  const handleOptionChange = (mode, value, name) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [mode]: { _id: value, name: name }, // Store both the _id and name under the mode key
    }));
  };
  const handleAmountChange = (mode, value) => {
    setAmounts((prev) => ({ ...prev, [mode]: value })); // Update the amount for the specified mode
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === "string" ? value.split(",") : value;
    const deselectedModes = selectedModes.filter(
      (mode) => !selected.includes(mode)
    );
    setSelectedModes(selected);
    deselectedModes.forEach((mode) => {
      setAmounts((prevAmounts) => {
        const newAmounts = { ...prevAmounts };
        delete newAmounts[mode]; // Delete the object property for deselected mode
        return newAmounts;
      });
    });
  };

  const validateForm = () => {
    const errors = {};
    selectedModes.forEach((mode) => {
      if (!selectedOptions[mode]) {
        errors[mode] = "Option is required";
      }
      if (!amounts[mode]) {
        errors[`${mode}_amount`] = "Amount is required";
      }
    });
    return errors;
  };

  const downloadPDF = (id) => {
    // setDownloading(true); // Set state to hide "Print" column
    const input = document.getElementById("report-content");
    if (!input) return;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "in",
        format: "letter",
      });
      const pdfWidth = 8.5;
      const pdfHeight = 11;
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const adjustedHeight = imgHeight > pdfHeight ? pdfHeight : imgHeight;

      // Set yOffset to 0 to reduce padding from the top
      const yOffset = 0;
      pdf.addImage(imgData, "PNG", 0, yOffset, imgWidth, adjustedHeight);
      pdf.save("INVOICE#" + id);
    });

    // setDownloading(false); // Reset state to show "Print" column
  };

  const payRefund = async (event) => {
    event.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    const combined = [];
    // Iterate through the selectedOptions object
    let totalAmount = 0;
    for (const [mode, account] of Object.entries(selectedOptions)) {
      // Check if the mode exists in the amounts object
      if (amounts.hasOwnProperty(mode)) {
        // Add the mode, account name, and amount to the combined array
        combined.push({
          mode: mode.toLowerCase(),
          account: account?._id ? account._id : "", // Access the name property and convert to lowercase
          amount: amounts[mode], // Get the corresponding amount
        });
        // Add the current amount to the total
        totalAmount = Number(totalAmount) + Number(amounts[mode]);
      }
    }

    const invoiceData = {
      ...invoice,
      paymentMode: combined,
      signature: "refund",
    };
    if (totalAmount >= invoiceData.paymentAmount) {
      message.error("Amount is more the Amount paid!");
      return;
    }

    try {
      const response = await ApiPath.put(`/invoice/${Id}`, invoiceData);
      // console.log("Invoice saved:", response.data);
      // alert("Invoice submitted successfully!");
      message.success("Item Refunded!");
      // getInvoiceData();
      fetchAllData();
      //  getInvoiceData();
    } catch (error) {
      console.error("Error submitting invoice:", error);
      message.error("Item Paid!");

      // alert("Failed to submit invoice.");
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, width: "80%", margin: "auto" }}>
      <form onSubmit={payRefund}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} id="report-content">
            <Box sx={{ p: 4 }}>
              <Box
                sx={{
                  mb: 4,
                  background: "pink",
                  borderRadius: 2,
                  padding: 5,
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <Box color="primary.main">
                        <img src={LogoImage} height="70" alt="Logo" />
                      </Box>
                    </Box>
                    <Typography>
                      Al Yasat tower, Office No 603, 6th floor
                    </Typography>
                    <Typography>Najda Street, Abu dhabi</Typography>
                    <Typography>+971 2 448 941 | +971 54 410 9635</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5">
                      Invoice #{invoice?.invoiceNo || "N/A"}
                    </Typography>
                    <Typography>
                      Date Issued:{" "}
                      {new Date(invoice?.createdAt).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography variant="h6">Refund To:</Typography>
                  <Typography>{invoice?.client?.name || "N/A"}</Typography>
                  <Typography>{invoice?.client?.email || "N/A"}</Typography>
                  <Typography>{invoice?.client?.mobile || "N/A"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="h6">Payment Details:</Typography>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Total Due:</TableCell>
                        <TableCell>
                          AED {invoice?.total?.toFixed(2) || "0.00"}
                        </TableCell>
                      </TableRow>
                      {invoice?.paymentMode?.map((payment, index) => (
                        <TableRow key={index}>
                          <TableCell>Payment Mode:</TableCell>
                          <TableCell>
                            <span style={{ textTransform: "capitalize" }}>
                              {" "}
                              {payment?.mode}
                            </span>{" "}
                            - AED {Number(payment?.amount).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <TableContainer component={Paper} sx={{ mt: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Passenger</TableCell>
                      <TableCell>Qty</TableCell>
                      <TableCell>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice?.items?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>
                          AED {Number(item.price).toFixed(2) || "0.00"}
                        </TableCell>
                        <TableCell>
                          {item.firstname + " " + item.lastname}
                        </TableCell>

                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>
                          AED{" "}
                          {(Number(item.price) * (item.quantity || 0)).toFixed(
                            2
                          ) || "0.00"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ mt: 4 }}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6}>
                    <Typography>
                      <strong>Sales Person:</strong> {invoice?.customer?.name}
                    </Typography>
                    <Typography>Thanks for your business</Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    {/* <Typography>
                      <strong>Subtotal:</strong> AED {invoice?.paymentAmount}.00
                    </Typography>
                    <Typography>
                      <strong>Discount:</strong> AED 0
                    </Typography>
                    <Typography>
                      <strong>Tax:</strong> AED 0
                    </Typography>
                    <Typography>
                      <strong>Balance :</strong> AED{" "}
                      {invoice?.balance?.toFixed(2)}
                    </Typography> */}
                    <Typography variant="h6">
                      <strong>Total:</strong> AED {invoice?.paymentAmount}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography>
                  <strong>Note:</strong> {invoice?.note || "N/A"}
                </Typography>
                <img
                  style={{
                    position: "absolute",
                    height: "150px",
                    marginLeft: "15vw",
                    marginTop: "-8vw",
                  }}
                  src={StampImage}
                />
              </Box>
            </Box>
          </Grid>

          {}

          <Grid item xs={12} lg={3}>
            <br />

            {refund && (
              <div>
                Select Payment
                <FormControl fullWidth style={{ marginTop: 15 }}>
                  <Select
                    multiple
                    value={selectedModes}
                    onChange={handleChange}
                    size="small"
                    renderValue={(selected) => selected.join(", ")} // Display selected values as comma-separated
                  >
                    {PaymentMode.map(({ mode }) => (
                      <MenuItem key={mode} value={mode}>
                        <Checkbox checked={selectedModes.indexOf(mode) > -1} />
                        <ListItemText
                          primary={mode}
                          style={{ textTransform: "capitalize" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <br />
                {selectedModes.map((mode) => {
                  const { options } =
                    PaymentMode.find((m) => m.mode === mode) || {};
                  return (
                    <Grid container item xs={12} spacing={1} key={mode}>
                      <Grid
                        item
                        xs={4}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="body1"
                          style={{ textTransform: "capitalize" }}
                        >
                          {mode}:
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {options && options.length > 0 && (
                          <FormControl fullWidth required>
                            <Autocomplete
                              options={options}
                              getOptionLabel={(subOption) =>
                                `${subOption.name} (Account: ${subOption.account})`
                              }
                              onChange={(event, newValue) => {
                                handleOptionChange(
                                  mode,
                                  newValue ? newValue._id : "",
                                  newValue ? newValue.name : ""
                                );
                              }} // Update selected option
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select Option"
                                  size="small"
                                  variant="outlined"
                                  required
                                />
                              )}
                              value={
                                options.find(
                                  (subOption) =>
                                    subOption._id === selectedOptions[mode]?._id
                                ) || null
                              }
                              isOptionEqualToValue={(option, value) =>
                                option._id === value._id
                              } // Comparison function by _id
                              fullWidth
                            />
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          size="small"
                          type="number"
                          placeholder="Enter amount"
                          value={amounts[mode] || ""} // Controlled input for amount
                          onChange={(e) =>
                            handleAmountChange(mode, e.target.value)
                          } // Update amount
                          style={{ width: "100%" }} // Full width
                          required
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                <br />
                <br />
              </div>
            )}
            <div></div>
            <Card sx={{ p: 3 }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<SendIcon />}
                sx={{ mb: 2 }}
                onClick={() => console.log("Send Invoice")}
              >
                Send Refund Note
              </Button>
              <Button
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                onClick={() => console.log("Preview Invoice")}
              >
                Print
              </Button>
              <Button
                variant="outlined"
                fullWidth
                onClick={() => downloadPDF(invoice?.invoiceNo)}
              >
                Save
              </Button>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ViewRefund;
