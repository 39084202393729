import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import AddAmenity from "./Add/AddAmedity";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridDeleteIcon } from "@mui/x-data-grid";
import AddNewItemForm from "./AddItems";
import { Modal as AntModal, message } from "antd";

import ApiPath from "../../utils/api";
import { useDataContext } from "../../context/Context";
const { confirm } = AntModal;

const RowOptions = ({ data, link }) => {
  const { fetchAllData } = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const deleteItem = async (link) => {
    confirm({
      title: "Are you sure you want to delete this item?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await ApiPath.delete(`/item/${data.row._id}`);
          message.success("Item deleted successfully");
          fetchAllData();
        } catch (error) {
          message.error("Failed to delete item");
        }
      },
      onCancel() {
        console.log("Cancel deletion");
      },
    });
  };
  const handleEdit = () => {
    setModalOpen(true);
  };
  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "4rem" } }}
      >
        <Link to={`/sales/view/${link}`}>
          <MenuItem
            component={Link}
            to={`/sales/view/${link}`}
            sx={{ "& svg": { marginRight: 2 } }}
            onClick={handleRowOptionsClose}
          >
            <RemoveRedEyeIcon color="primary" fontSize="small" />
            View
          </MenuItem>
        </Link>
        <MenuItem onClick={handleEdit} sx={{ "& svg": { marginRight: 2 } }}>
          <EditIcon color="primary" fontSize="small" />
          Edit
        </MenuItem>

        <MenuItem
          component={Link}
          sx={{ "& svg": { marginRight: 2 } }}
          onClick={() => deleteItem(deleteItem)}
        >
          <GridDeleteIcon sx={{ fontSize: "20px" }} />
          Delete
        </MenuItem>
      </Menu>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <AddNewItemForm data={data.row} />
        </Box>
      </Modal>
    </>
  );
};

export default RowOptions;
