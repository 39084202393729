import LogoImage from "../assets/bestlogo.svg";
import StampImage from "../assets/opc.PNG";

 
export { LogoImage };
export { StampImage };

export const itemData = [
  {
    _id: {
      $oid: "632459f427d20ff8c46c612e",
    },
    Id: "15",
    ItemCode: "IT014",
    ItemName: "Air Ticket",
    ItemCategory: "Airticket",
    Tags: "Oneway",
    Photo: "None",
    SellingPrice: "1",
    PurchasePrice: "1",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Oneway",
    ItemDescription2: "Oneway",
    Notes: "Oneway",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6130",
    },
    Id: "14",
    ItemCode: "IT013",
    ItemName: "Abscond Removal",
    ItemCategory: "Abscond",
    Tags: "Abudhabi",
    Photo: "None",
    SellingPrice: "3500",
    PurchasePrice: "3500",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Abudhabi",
    ItemDescription2: "Abudhabi",
    Notes: "Abudhabi",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c612f",
    },
    Id: "16",
    ItemCode: "IT015",
    ItemName: "Air Ticket",
    ItemCategory: "Airticket",
    Tags: "Return",
    Photo: "None",
    SellingPrice: "1",
    PurchasePrice: "1",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Return",
    ItemDescription2: "Return",
    Notes: "Return",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c612c",
    },
    Id: "17",
    ItemCode: "IT016",
    ItemName: "Air Ticket",
    ItemCategory: "Airticket",
    Tags: "Cancel",
    Photo: "None",
    SellingPrice: "100",
    PurchasePrice: "25",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Cancel",
    ItemDescription2: "Cancel",
    Notes: "Cancel",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c612d",
    },
    Id: "18",
    ItemCode: "IT017",
    ItemName: "PCR Test",
    ItemCategory: "PCR",
    Tags: "Charges",
    Photo: "None",
    SellingPrice: "150",
    PurchasePrice: "100",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "PCR Test",
    ItemDescription2: "PCR Test",
    Notes: "PCR Test",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6131",
    },
    Id: "13",
    ItemCode: "IT012",
    ItemName: "Abscond fee",
    ItemCategory: "Abscond",
    Tags: "Abudhabi",
    Photo: "None",
    SellingPrice: "1000",
    PurchasePrice: "1000",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Abudhabi",
    ItemDescription2: "Abudhabi",
    Notes: "Abudhabi",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c613f",
    },
    Id: "55",
    ItemCode: "IT0019",
    ItemName: "freelance visa",
    ItemCategory: "Visa",
    Tags: "freelance visa",
    Photo: "",
    SellingPrice: "8500",
    PurchasePrice: "8500",
    Account: "Sales",
    Account2: "NBD",
    ItemDescription: "freelance visa",
    ItemDescription2: "freelance visa",
    Notes: "",
    Poster: "",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6143",
    },
    Id: "60",
    ItemCode: "IT0023",
    ItemName: "Hotel ",
    ItemCategory: "Hotel Booking",
    Tags: "Booking",
    Photo: "",
    SellingPrice: "150",
    PurchasePrice: "100",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Booking",
    ItemDescription2: "Booking",
    Notes: "",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6132",
    },
    Id: "11",
    ItemCode: "IT010",
    ItemName: "Abscond fee",
    ItemCategory: "Abscond",
    Tags: "Dubai",
    Photo: "None",
    SellingPrice: "2000",
    PurchasePrice: "1000",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Charges",
    ItemDescription2: "Charges",
    Notes: "Charges",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6134",
    },
    Id: "9",
    ItemCode: "IT008",
    ItemName: "Overstay Fines",
    ItemCategory: "Fines",
    Tags: "Fines (ICA)",
    Photo: "None",
    SellingPrice: "1",
    PurchasePrice: "1",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Fines (ICA)",
    ItemDescription2: "Fines (ICA)",
    Notes: "Fines (ICA)",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6136",
    },
    Id: "8",
    ItemCode: "IT007",
    ItemName: "Cancellation",
    ItemCategory: "Visa",
    Tags: " Fees",
    Photo: "None",
    SellingPrice: "170",
    PurchasePrice: "150",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Immigration fee",
    ItemDescription2: "Immigration fee",
    Notes: "Immigration fee",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6137",
    },
    Id: "7",
    ItemCode: "IT006",
    ItemName: "90 Days Visa",
    ItemCategory: "Visa",
    Tags: "Multiple Entry",
    Photo: "None",
    SellingPrice: "1900",
    PurchasePrice: "1670",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Multiple Entry",
    ItemDescription2: "Multiple Entry",
    Notes: "Multiple Entry",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6139",
    },
    Id: "5",
    ItemCode: "IT004",
    ItemName: "90 Days Visa",
    ItemCategory: "Visa",
    Tags: "Incountry",
    Photo: "None",
    SellingPrice: "1450",
    PurchasePrice: "1210",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Incountry",
    ItemDescription2: "Incountry",
    Notes: "Incountry",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c613c",
    },
    Id: "2",
    ItemCode: "IT001",
    ItemName: "30 Days Visa",
    ItemCategory: "Visa",
    Tags: "Incountry",
    Photo: "None",
    SellingPrice: "1200",
    PurchasePrice: "950",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Incountry",
    ItemDescription2: "Incountry",
    Notes: "Incountry",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c613d",
    },
    Id: "19",
    ItemCode: "IT018",
    ItemName: "Airport Pickup",
    ItemCategory: "Transport",
    Tags: "Charges",
    Photo: "None",
    SellingPrice: "200",
    PurchasePrice: "100",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Charges",
    ItemDescription2: "Charges",
    Notes: "Charges",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6133",
    },
    Id: "12",
    ItemCode: "IT011",
    ItemName: "Abscond Removal",
    ItemCategory: "Abscond",
    Tags: "Dubai",
    Photo: "None",
    SellingPrice: "1150",
    PurchasePrice: "1150",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Charges",
    ItemDescription2: "Charges",
    Notes: "Charges",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6140",
    },
    Id: "56",
    ItemCode: "IT0020",
    ItemName: "TICKET REBOOK",
    ItemCategory: "Air Ticket",
    Tags: "TICKET",
    Photo: "",
    SellingPrice: "700",
    PurchasePrice: "700",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "TICKET",
    ItemDescription2: "TICKET",
    Notes: "",
    Poster: "",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6142",
    },
    Id: "58",
    ItemCode: "IT0022",
    ItemName: "90 days ",
    ItemCategory: "Visa",
    Tags: "incountry",
    Photo: "",
    SellingPrice: "1300",
    PurchasePrice: "1300",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "advance payment",
    ItemDescription2: "incountry",
    Notes: "",
    Poster: "",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6144",
    },
    Id: "61",
    ItemCode: "IT0024",
    ItemName: "bOAT cRUISE",
    ItemCategory: "Tours",
    Tags: "CRUISE",
    Photo: "",
    SellingPrice: "100",
    PurchasePrice: "100",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "CRUISE",
    ItemDescription2: "CRUISE",
    Notes: "",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6135",
    },
    Id: "10",
    ItemCode: "IT009",
    ItemName: "Fines Typing Fee",
    ItemCategory: "Fines",
    Tags: "Charges",
    Photo: "None",
    SellingPrice: "25",
    PurchasePrice: "40",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Charges",
    ItemDescription2: "Charges",
    Notes: "Charges",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c6138",
    },
    Id: "6",
    ItemCode: "IT005",
    ItemName: "90 Days Visa",
    ItemCategory: "Visa",
    Tags: "Outsidecountry",
    Photo: "None",
    SellingPrice: "850",
    PurchasePrice: "670",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Outsidecountry",
    ItemDescription2: "Outsidecountry",
    Notes: "Outsidecountry",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c613a",
    },
    Id: "4",
    ItemCode: "IT003",
    ItemName: "30 Days Visa",
    ItemCategory: "Visa",
    Tags: "Multiple Entry",
    Photo: "None",
    SellingPrice: "1200",
    PurchasePrice: "900",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Multiple Entry",
    ItemDescription2: "Multiple Entry",
    Notes: "Multiple Entry",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c613b",
    },
    Id: "3",
    ItemCode: "IT002",
    ItemName: "30 Days Visa",
    ItemCategory: "Visa",
    Tags: "Outsidecountry",
    Photo: "None",
    SellingPrice: "400",
    PurchasePrice: "295",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Outsidecountry",
    ItemDescription2: "Outsidecountry",
    Notes: "Outsidecountry",
    Poster: "George",
  },
  {
    _id: {
      $oid: "632459f427d20ff8c46c613e",
    },
    Id: "20",
    ItemCode: "IT019",
    ItemName: "Tour Package",
    ItemCategory: "Tours",
    Tags: "UAE",
    Photo: "None",
    SellingPrice: "1",
    PurchasePrice: "1",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "UAE",
    ItemDescription2: "UAE",
    Notes: "UAE",
    Poster: "George",
  },
  {
    _id: {
      $oid: "6335afb8ac4b339c109e9ff1",
    },
    Id: "17",
    ItemCode: "IT016",
    ItemName: "insurance",
    ItemCategory: "Service",
    Tags: "Service",
    Photo: "None",
    SellingPrice: "100",
    PurchasePrice: "25",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Service",
    ItemDescription2: "Service",
    Notes: "Service",
    Poster: "George",
  },
  {
    _id: {
      $oid: "633ab0a67aef3a9fa52939d6",
    },
    Id: "65",
    ItemCode: "IT004",
    ItemName: "60 Days Visa",
    ItemCategory: "Visa",
    Tags: "2M Tourist A",
    Photo: "None",
    SellingPrice: "1450",
    PurchasePrice: "1210",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "2M Tourist A2A",
    ItemDescription2: "2M Tourist A2A",
    Notes: "2M Tourist A",
    Poster: "George",
  },
  {
    _id: {
      $oid: "633ab0a67aef3a9fa52939d7",
    },
    Id: "64",
    ItemCode: "IT005",
    ItemName: "60 Days Visa",
    ItemCategory: "Visa",
    Tags: "2M Tourist",
    Photo: "None",
    SellingPrice: "850",
    PurchasePrice: "670",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "2M Tourist",
    ItemDescription2: "2M Tourist",
    Notes: "2M Tourist",
    Poster: "George",
  },
  {
    _id: {
      $oid: "633ab0a67aef3a9fa52939d8",
    },
    Id: "63",
    ItemCode: "IT006",
    ItemName: "60 Days Visa",
    ItemCategory: "Visa",
    Tags: "Multi 2M Tourist Multi",
    Photo: "None",
    SellingPrice: "1900",
    PurchasePrice: "1670",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Multi 2M Tourist Multi",
    ItemDescription2: "Multi 2M Tourist Multi",
    Notes: "Multi 2M Tourist Multi",
    Poster: "George",
  },
  {
    _id: {
      $oid: "633d62cd78905b637ef29dc5",
    },
    Id: "15",
    ItemCode: "IT014",
    ItemName: "Reimbursment",
    ItemCategory: "Visa",
    Tags: " ",
    Photo: "None",
    SellingPrice: "1",
    PurchasePrice: "1",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: " ",
    ItemDescription2: " ",
    Notes: " ",
    Poster: "George",
  },
  {
    _id: {
      $oid: "633ea6fefe810b6866f3a7d1",
    },
    Id: "10",
    ItemCode: "IT009",
    ItemName: "Fines ",
    ItemCategory: "Fines",
    Tags: "Charges",
    Photo: "None",
    SellingPrice: "25",
    PurchasePrice: "40",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Reduction",
    ItemDescription2: "Reduction",
    Notes: "Charges",
    Poster: "George",
  },
  {
    _id: {
      $oid: "636286aea6f3933e4cd9427c",
    },
    ItemCategory: "Transport",
    ItemCode: "IT00",
    PurchasePrice: "300",
    Account2: "Cost of Goods Sold",
    ItemDescription: "Rental",
    ItemDescription2: "Rental",
    Photo: "",
    ItemName: "Car",
    Tags: "Rental",
    Account: "Advertising And Marketing",
    Poster: "George",
    SellingPrice: "500",
    Notes: "",
  },
  {
    _id: {
      $oid: "63a04cba53fa7d5e345fb334",
    },
    Account2: "Rent Expense",
    Account: "Rent Expense",
    ItemCode: "IT00",
    Poster: "George",
    SellingPrice: "1000",
    ItemDescription: "PAY",
    PurchasePrice: "1000",
    ItemName: "RENT",
    ItemCategory: "Other",
    ItemDescription2: "PAY",
    Notes: "",
    Photo: "",
    Tags: " PAY",
  },
  {
    _id: {
      $oid: "63a310ad71d387cd13b48396",
    },
    Id: "17",
    ItemCode: "IT016",
    ItemName: "1 Month Visa",
    ItemCategory: "Extension",
    Tags: "Extension",
    Photo: "None",
    SellingPrice: "100",
    PurchasePrice: "25",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "Extension",
    ItemDescription2: "Extension",
    Notes: "Extension",
    Poster: "George",
  },
  {
    _id: {
      $oid: "63ff6beababa0f41f965db05",
    },
    Poster: "George",
    ItemDescription2: "BOOKING",
    ItemDescription: "BOOKING",
    Tags: "BOOKING",
    ItemCategory: "Air Ticket",
    Photo: "",
    SellingPrice: "30",
    ItemName: "TICKET ",
    Account: "Sales",
    PurchasePrice: "15",
    ItemCode: "IT00",
    Account2: "Sales",
    Notes: "",
  },
  {
    _id: {
      $oid: "643654616231ac9d2ee78579",
    },
    Id: "15",
    ItemCode: "IT014",
    ItemName: "SECURITY",
    ItemCategory: "visa",
    Tags: "DEPOSIT",
    Photo: "None",
    SellingPrice: "1",
    PurchasePrice: "1",
    Account: "Sales",
    Account2: "Sales",
    ItemDescription: "DEPOSIT",
    ItemDescription2: "DEPOSIT",
    Notes: "DEPOSIT",
    Poster: "George",
  },
  {
    _id: {
      $oid: "643fbe4e56c5c0b56bd07fca",
    },
    Poster: "George",
    ItemCode: "IT00",
    ItemDescription2: " OPENING",
    ItemName: "ACCOUNT",
    Tags: " OPENING",
    SellingPrice: "100",
    Account2: "Depreciation Expense",
    Photo: "",
    PurchasePrice: "100",
    Account: "Advertising And Marketing",
    ItemCategory: "Other",
    ItemDescription: " OPENING",
    Notes: "",
  },
  {
    _id: {
      $oid: "643fbe7864f4bbb608f3289f",
    },
    Poster: "George",
    Tags: "DATA CHANGE",
    SellingPrice: "100",
    PurchasePrice: "100",
    Notes: "",
    ItemName: "BANK ACCOUNT STATIC ",
    ItemCategory: "Account Opening",
    Account2: "Consultant Expense",
    ItemDescription: "DATA CHANGE",
    ItemDescription2: "DATA CHANGE",
    Photo: "",
    Account: "Drawings",
    ItemCode: "IT00",
  },
  {
    _id: {
      $oid: "6656e38ab21135c5a60bfb49",
    },
    ItemCode: "IT00",
    ItemDescription: "Fee",
    ItemName: "Outpass",
    ItemDescription2: "Fee",
    Notes: "",
    PurchasePrice: "100",
    Account: "NBD",
    Account2: "NBD",
    ItemCategory: "Other",
    Tags: "Outpass",
    Photo: "",
    Poster: "George",
    SellingPrice: "100",
  },
];

export const customerData = [
  {
    Note: "",
    FirstName: "George",
    Country: "Uganda",
    Telephone: "",
    Poster: "Cashier",
    Salutation: "Mr.",
    RefereeEmail: "",
    Nationality: "",
    SalePerson: "Harriet",
    OpeningBal: "0",
    Category: "Walk In",
    Address: "",
    LastName: "okurut",
    Mobile: "0563087636",
    RefereeMobile: "",
    Email: "okurut17@gmail.com",
    PaymentTerms: "",
    Tags: "",
    City: "",
    RefereeName: "relax",
  },
  {
    PaymentTerms: "",
    Tags: "",
    LastName: "Dubai",
    FirstName: "Ambrose",
    Poster: "Cashier",
    City: "",
    Address: "",
    Salutation: "Mr.",
    Mobile: "+971551156008",
    Note: "",
    Nationality: "",
    Category: "",
    Email: "ambrose@gmail.com",
    RefereeName: "Harriet",
    RefereeEmail: "",
    Telephone: "",
    RefereeMobile: "",
    SalePerson: "",
    Country: "",
    OpeningBal: "0",
  },
  {
    City: "",
    FirstName: "Sham",
    Nationality: "",
    Note: "",
    RefereeMobile: "",
    OpeningBal: "0",
    Telephone: "",
    Category: "",
    Email: "shamevents@gmail.com",
    Mobile: "+971543481532",
    PaymentTerms: "",
    LastName: "Events",
    Tags: "",
    Poster: "Cashier",
    Address: "",
    SalePerson: "",
    Salutation: "Mr.",
    RefereeName: "Relaxation",
    RefereeEmail: "",
    Country: "",
  },
  {
    Category: "",
    SalePerson: "",
    Email: "ujemg@gmail.com",
    Telephone: "",
    PaymentTerms: "",
    City: "",
    Country: "",
    RefereeEmail: "",
    FirstName: "Ujem",
    Salutation: "Mr.",
    RefereeMobile: "",
    Poster: "Cashier",
    Address: "",
    Note: "",
    OpeningBal: "0",
    RefereeName: "Relaxation",
    Nationality: "",
    Tags: "",
    LastName: "group",
    Mobile: "+971556554093",
  },
  {
    SalePerson: "",
    RefereeEmail: "",
    RefereeName: "Relaxation",
    Category: "",
    Address: "",
    Mobile: "+971542795123",
    City: "",
    RefereeMobile: "",
    FirstName: "Sharon",
    Salutation: "Mr.",
    OpeningBal: "0",
    Telephone: "",
    Country: "",
    Note: "",
    Nationality: "",
    PaymentTerms: "",
    Tags: "",
    Email: "sharondubai@gamil.com",
    Poster: "Cashier",
    LastName: "Dubai",
  },
  {
    Email: "openvision@gmail.com",
    Salutation: "Mr.",
    SalePerson: "",
    Poster: "Cashier",
    Mobile: "+971543702184",
    Note: "",
    City: "",
    FirstName: "Open vision",
    Country: "",
    Category: "",
    LastName: "tourism",
    Telephone: "",
    Address: "",
    RefereeMobile: "",
    PaymentTerms: "",
    RefereeEmail: "",
    OpeningBal: "0",
    Nationality: "",
    Tags: "",
    RefereeName: "Relaxation",
  },
  {
    City: "",
    PaymentTerms: "",
    RefereeMobile: "",
    Email: "mezontourism@gmail.com",
    Telephone: "",
    LastName: "tourism",
    Mobile: "+971504463187",
    Country: "",
    Nationality: "",
    Salutation: "Mr.",
    Note: "",
    FirstName: "Mezon",
    Address: "",
    OpeningBal: "0",
    Category: "",
    Tags: "",
    Poster: "Cashier",
    RefereeEmail: "",
    SalePerson: "",
    RefereeName: "Relaxation",
  },
  {
    OpeningBal: "0",
    Nationality: "",
    Country: "",
    City: "",
    LastName: "Money",
    RefereeName: "Relaxation",
    Mobile: "+971557804387",
    Poster: "Cashier",
    Tags: "",
    Note: "",
    SalePerson: "",
    Salutation: "Mr.",
    FirstName: "Brian Mobile",
    RefereeEmail: "",
    Email: "brianmobile@gmail.com",
    Address: "",
    RefereeMobile: "",
    PaymentTerms: "",
    Telephone: "",
    Category: "",
  },
  {
    LastName: "Vision",
    Email: "juliusopen@gmail.com",
    FirstName: "Julius open",
    SalePerson: "",
    RefereeName: "Relaxation",
    Salutation: "Ms.",
    Poster: "Cashier",
    Note: "",
    RefereeMobile: "",
    Address: "",
    PaymentTerms: "",
    Mobile: "+971589204768",
    City: "",
    Country: "",
    Tags: "",
    Telephone: "",
    Nationality: "",
    RefereeEmail: "",
    OpeningBal: "0",
    Category: "",
  },
  {
    Email: "travelguru@gmail.com",
    LastName: "Guru",
    Telephone: "",
    FirstName: "Travel",
    Category: "",
    Address: "",
    Mobile: "+971554549976",
    Tags: "",
    Poster: "Cashier",
    PaymentTerms: "",
    Country: "",
    RefereeEmail: "",
    Salutation: "Mr.",
    Nationality: "",
    RefereeMobile: "",
    Note: "",
    City: "",
    OpeningBal: "0",
    RefereeName: "Relaxation",
    SalePerson: "",
  },
  {
    PaymentTerms: "",
    Note: "",
    City: "",
    Country: "",
    RefereeName: "Relaxation",
    Tags: "",
    Nationality: "",
    RefereeMobile: "",
    Poster: "Cashier",
    Address: "",
    RefereeEmail: "",
    FirstName: "Brenda smart",
    Email: "brendasmart@gmail.com",
    Mobile: "+971586674854",
    LastName: "Box",
    Telephone: "",
    OpeningBal: "0",
    Category: "",
    SalePerson: "",
    Salutation: "Ms.",
  },
  {
    Tags: "",
    Poster: "Cashier",
    Address: "",
    City: "",
    FirstName: "Miles human",
    RefereeName: "Relaxation",
    OpeningBal: "0",
    SalePerson: "",
    Note: "",
    LastName: "Resource",
    RefereeMobile: "",
    Telephone: "",
    Mobile: "+971507932066",
    Nationality: "",
    Salutation: "Mr.",
    PaymentTerms: "",
    Category: "",
    Email: "mileshuman@gmail.com",
    RefereeEmail: "",
    Country: "",
  },
  {
    City: "",
    Nationality: "",
    PaymentTerms: "",
    Mobile: "+971528366483",
    RefereeName: "Relaxation",
    Address: "",
    Category: "",
    Poster: "Cashier",
    Country: "",
    FirstName: "Damac",
    Email: "damactravel@gmail.com",
    Telephone: "",
    LastName: "Travels",
    Tags: "",
    Note: "",
    RefereeMobile: "",
    RefereeEmail: "",
    Salutation: "Mr.",
    SalePerson: "",
    OpeningBal: "0",
  },
];

export const PaymentMode = [
  {
    _id: "1324",
    mode: "Bank",
    option: [
      { name: "ADCB", account: "1234567890" },
      { name: "WIO", account: "1234567890" },
      { name: "ADIB", account: "1234567890" },
    ],
  },
  {
    _id: "243553",
    mode: "Mobile money",
    option: [
      { name: "Airtel Money", account: "1234567890" },
      { name: "Mtn Money", account: "1234567890" },
    ],
  },
  {
    _id: "52563",
    mode: "Cash",
    option: [{ name: "Cash", account: "" }],
  },
];

export const vendorsData = [
  {
    _id: {
      $oid: "6347df476ae59878b1de5f76",
    },
    Id: "265",
    Salutation: "Mr.",
    FirstName: "JEFF",
    FullName: "JEFF KIZITO",
    LastName: "KIZITO",
    Mobile: "+971568308492",
    Telephone: "",
    Email: "kizitojeff2017@gmail.com",
    Tags: "0",
    OpeningBal: "0",
    Address: "HAMDAN",
    City: "ABU DHABI",
    Country: "United Arab Emirates",
    Nationality: "Uganda",
    SalePerson: "Vicky  Nyakake",
    Category: "Walk In",
    RefereeName: "JEFF",
    RefereeMobile: "+971568308492",
    RefereeEmail: "kizitojeff2017@gmail.com",
    PaymentTerms: "",
    Note: "",
    Poster: "George",
  },
  {
    _id: {
      $oid: "6347df476ae59878b1de5f77",
    },
    Id: "264",
    Salutation: "Mr.",
    FirstName: "SAMUEL",
    FullName: "SAMUEL KIRUNDA",
    LastName: "KIRUNDA",
    Mobile: "+971586528342",
    Telephone: "+971586528342",
    Email: "trevorkirunda1@gmail.com",
    Tags: "11",
    OpeningBal: "0",
    Address: "dubai",
    City: "deira",
    Country: "United Arab Emirates",
    Nationality: "Uganda",
    SalePerson: "Julius  Ssengonzi",
    Category: "Walk In",
    RefereeName: "julius",
    RefereeMobile: "+971568696014",
    RefereeEmail: "julius@Speedexpress.Com",
    PaymentTerms: "",
    Note: "",
    Poster: "George",
  },
  {
    _id: {
      $oid: "6347df476ae59878b1de5f78",
    },
    Id: "263",
    Salutation: "Mr.",
    FirstName: "BRIAN",
    FullName: "BRIAN SSENYONGA",
    LastName: "SSENYONGA",
    Mobile: "+971509967382",
    Telephone: "",
    Email: "brianssenyonga@gmail.com",
    Tags: "0",
    OpeningBal: "0",
    Address: "HAMDAN",
    City: "ABU DHABI",
    Country: "United Arab Emirates",
    Nationality: "Uganda",
    SalePerson: "Brenda  Acan",
    Category: "Walk In",
    RefereeName: "BRIAN",
    RefereeMobile: "+971509967382",
    RefereeEmail: "Brianssenyonga@Gmail.Com",
    PaymentTerms: "",
    Note: "",
    Poster: "George",
  },
  {
    _id: {
      $oid: "65e2bfd0923aff9abbd13189",
    },
    RefereeName: "Relaxation",
    Address: "",
    Salutation: "Mr.",
    Poster: "Cashier",
    Email: "alajwatourism@gmail.com",
    Nationality: "",
    FirstName: "Al Ajwah",
    RefereeEmail: "",
    Mobile: "0564170545",
    City: "",
    Tags: "",
    PaymentTerms: "",
    Telephone: "",
    Note: "",
    OpeningBal: "0",
    SalePerson: "",
    RefereeMobile: "",
    Country: "",
    Category: "",
    LastName: "tourism",
  },
  {
    _id: {
      $oid: "65e2c03fde918d1583dfda13",
    },
    City: "",
    SalePerson: "",
    LastName: "tourism",
    PaymentTerms: "",
    RefereeName: "Relaxation",
    RefereeMobile: "",
    Note: "",
    FirstName: "Falcon",
    OpeningBal: "0",
    Telephone: "",
    Tags: "",
    Country: "",
    Mobile: "0585925014",
    Address: "",
    RefereeEmail: "",
    Salutation: "Mr.",
    Nationality: "",
    Email: "falcontourism@gmail.com",
    Poster: "Cashier",
    Category: "",
  },
  {
    _id: {
      $oid: "65e2c0ecf1128ebaafc84c14",
    },
    Email: "markhortourism@gmail.com",
    Note: "",
    Telephone: "",
    RefereeName: "Relaxation",
    Nationality: "",
    LastName: "tourism",
    Salutation: "Mr.",
    City: "",
    PaymentTerms: "",
    Country: "",
    SalePerson: "",
    FirstName: "Markhor",
    Tags: "",
    Poster: "Cashier",
    Category: "",
    OpeningBal: "0",
    RefereeMobile: "",
    RefereeEmail: "",
    Mobile: "0506934289",
    Address: "",
  },
  {
    _id: {
      $oid: "66d99de0418825d359212434",
    },
    LastName: "Travel",
    RefereeEmail: "",
    Nationality: "",
    RefereeMobile: "",
    Mobile: "",
    Telephone: "",
    SalePerson: "",
    Address: "",
    Email: "quebectravel@gmail.com",
    OpeningBal: "0",
    Note: "",
    Country: "",
    FirstName: "Quebec ",
    City: "",
    RefereeName: "Harriet",
    Salutation: "Mr.",
    Category: "",
    Tags: "",
    PaymentTerms: "",
    Poster: "Cashier",
  },
  {
    _id: {
      $oid: "66ed7b2cb8f4e6acd8d9f7a3",
    },
    Nationality: "",
    Note: "",
    FirstName: "Progoti",
    PaymentTerms: "",
    LastName: "Travels ",
    Country: "",
    RefereeMobile: "",
    SalePerson: "",
    OpeningBal: "0",
    RefereeEmail: "",
    RefereeName: "Harriet",
    Salutation: "Mr.",
    Category: "",
    Telephone: "",
    City: "",
    Mobile: "",
    Address: "",
    Email: "progotitravel@gmail.com",
    Poster: "Cashier",
    Tags: "",
  },
];

export const countryData = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const Accounts = [
  {
    Id: "1",
    AccountID: "202201",
    AccountName: "Accounts Payable",
    AccountType: "Accounts Payable",
    Description:
      "This is an account of all the money which you owe to others like a pending bill payment to a vendor,etc.",
  },
  {
    Id: "2",
    AccountID: "202202",
    AccountName: "Accounts Receivable",
    AccountType: "Accounts Receivable",
    Description:
      "The money that customers owe you becomes the accounts receivable. A good example of this is a payment expected from an invoice sent to your customer.",
  },
  {
    Id: "3",
    AccountID: "202203",
    AccountName: "Advance Tax",
    AccountType: "Other Current Asset",
    Description:
      "Any tax which is paid in advance is recorded into the advance tax account. This advance tax payment could be a quarterly, half yearly or yearly payment.",
  },
  {
    Id: "4",
    AccountID: "202204",
    AccountName: "Advertising And Marketing",
    AccountType: "Expense",
    Description:
      "Your expenses on promotional, marketing and advertising activities like banners, web-adds, trade shows, etc. are recorded in advertising and marketing account.",
  },
  {
    Id: "5",
    AccountID: "202205",
    AccountName: "Automobile Expense",
    AccountType: "Expense",
    Description:
      "Transportation related expenses like fuel charges and maintenance charges for automobiles, are included to the automobile expense account.",
  },
  {
    Id: "6",
    AccountID: "202206",
    AccountName: "Bad Debt",
    AccountType: "Expense",
    Description:
      "Any amount which is lost and is unrecoverable is recorded into the bad debt account.",
  },
  {
    Id: "7",
    AccountID: "202207",
    AccountName: "Bank Fees and Charges",
    AccountType: "Expense",
    Description:
      "Any bank fees levied is recorded into the bank fees and charges account. A bank account maintenance fee, transaction charges, a late payment fee are some examples.",
  },
  {
    Id: "8",
    AccountID: "202208",
    AccountName: "Consultant Expense",
    AccountType: "Expense",
    Description:
      "Charges for availing the services of a consultant is recorded as a consultant expenses. The fees paid to a soft skills consultant to impart personality development training for your employees is a good example.",
  },
  {
    Id: "9",
    AccountID: "202209",
    AccountName: "Cost of Goods Sold",
    AccountType: "Cost Of Goods Sold",
    Description: "An expense account which tracks the value of the goods sold.",
  },
  {
    Id: "10",
    AccountID: "202210",
    AccountName: "Credit Card Charges",
    AccountType: "Expense",
    Description:
      "Service fees for transactions , balance transfer fees, annual credit fees and other charges levied on a credit card are recorded into the credit card account.",
  },
  {
    Id: "11",
    AccountID: "202211",
    AccountName: "Depreciation Expense",
    AccountType: "Expense",
    Description:
      "Any depreciation in value of your assets can be captured as a depreciation expense.",
  },
  {
    Id: "12",
    AccountID: "202212",
    AccountName: "Discount",
    AccountType: "Income",
    Description:
      "Any reduction on your selling price as a discount can be recorded into the discount account.",
  },
  {
    Id: "13",
    AccountID: "202213",
    AccountName: "Drawings",
    AccountType: "Equity",
    Description:
      "The money withdrawn from a business by its owner can be tracked with this account.",
  },
  {
    Id: "14",
    AccountID: "202214",
    AccountName: "Employee Advance",
    AccountType: "Other Current Asset",
    Description:
      "Money paid out to an employee in advance can be tracked here till it's repaid or shown to be spent for company purposes.",
  },
  {
    Id: "15",
    AccountID: "202215",
    AccountName: "Employee Reimbursements",
    AccountType: "Other Current Liability",
    Description:
      "This account can be used to track the reimbursements that are due to be paid out to employees.",
  },
  {
    Id: "16",
    AccountID: "202216",
    AccountName: "Exchange Gain or Loss",
    AccountType: "Other Expense",
    Description:
      "Changing the conversion rate can result in a gain or a loss. You can record this into the exchange gain or loss account.",
  },
  {
    Id: "17",
    AccountID: "202217",
    AccountName: "Excise Tax Payable",
    AccountType: "Other Current Liability",
    Description: "The amount of Excise Tax which you owe.",
  },
  {
    Id: "18",
    AccountID: "202218",
    AccountName: "Fuel/Mileage Expenses",
    AccountType: "Expense",
    Description:
      "This category is for tracking all the mileage related expenses.",
  },
  {
    Id: "19",
    AccountID: "202219",
    AccountName: "Furniture and Equipment",
    AccountType: "Fixed Asset",
    Description:
      "Purchases of furniture and equipment for your office that can be used for a long period of time usually exceeding one year can be tracked with this account.",
  },
  {
    Id: "20",
    AccountID: "202220",
    AccountName: "General Income",
    AccountType: "Income",
    Description:
      "A general category of account where you can record any income which cannot be recorded into any other category.",
  },
  {
    Id: "21",
    AccountID: "202221",
    AccountName: "Input Excise Tax",
    AccountType: "Other Current Asset",
    Description:
      "The amount of money charged to you as Excise Tax on your purchases.",
  },
  {
    Id: "22",
    AccountID: "202222",
    AccountName: "Input VAT",
    AccountType: "Other Current Asset",
    Description: "The amount of money charged to you as VAT on your purchases.",
  },
  {
    Id: "23",
    AccountID: "202223",
    AccountName: "Interest Income",
    AccountType: "Income",
    Description:
      "A percentage of your balances and deposits are given as interest to you by your banks and financial institutions. This interest is recorded into the interest income account.",
  },
  {
    Id: "24",
    AccountID: "202224",
    AccountName: "Inventory Asset",
    AccountType: "Stock",
    Description:
      "An account which tracks the value of goods in your inventory.",
  },
  {
    Id: "26",
    AccountID: "202226",
    AccountName: "IT and Internet Expenses",
    AccountType: "Expense",
    Description:
      "Money spent on your IT infrastructure and usage like  internet connection, purchasing computer equipment etc is recorded as an IT and Computer Expense.",
  },
  {
    Id: "27",
    AccountID: "202227",
    AccountName: "Janitorial Expense",
    AccountType: "Expense",
    Description:
      "All your janitorial and cleaning expenses are recorded into the janitorial expenses account.",
  },
  {
    Id: "28",
    AccountID: "202228",
    AccountName: "Late Fee Income",
    AccountType: "Income",
    Description:
      "Any late fee income is recorded into the late fee income account. The late fee is levied when the payment for an invoice is not received by the due date.",
  },
  {
    Id: "29",
    AccountID: "202229",
    AccountName: "Lodging",
    AccountType: "Expense",
    Description:
      "Any expense related to putting up at motels etc while on business travel can be entered here.",
  },
  {
    Id: "30",
    AccountID: "202230",
    AccountName: "Meals and Entertainment",
    AccountType: "Expense",
    Description:
      "Expenses on food and entertainment are recorded into this account.",
  },
  {
    Id: "31",
    AccountID: "202231",
    AccountName: "Office Supplies",
    AccountType: "Expense",
    Description:
      "All expenses on purchasing office supplies like stationery are recorded into the office supplies account.",
  },
  {
    Id: "32",
    AccountID: "202232",
    AccountName: "Opening Balance Adjustments",
    AccountType: "Other Current Liability",
    Description:
      "This account will hold the difference in the debits and credits entered during the opening balance.",
  },
  {
    Id: "33",
    AccountID: "202233",
    AccountName: "Opening Balance Offset",
    AccountType: "Equity",
    Description:
      "This is an account where you can record the balance from your previous years earning or the amount set aside for some activities. It is like a buffer account for your funds.",
  },
  {
    Id: "34",
    AccountID: "202234",
    AccountName: "Other Charges",
    AccountType: "Income",
    Description:
      "Miscellaneous charges like adjustments made to the invoice can be recorded in this account.",
  },
  {
    Id: "35",
    AccountID: "202235",
    AccountName: "Other Expenses",
    AccountType: "Expense",
    Description:
      "Any minor expense on activities unrelated to primary business operations is recorded under the other expense account.",
  },
  {
    Id: "36",
    AccountID: "202236",
    AccountName: "Output Excise Tax",
    AccountType: "Other Current Liability",
    Description: "The amount of money charged as Excise Tax on your sales.",
  },
  {
    Id: "37",
    AccountID: "202237",
    AccountName: "Output VAT",
    AccountType: "Other Current Liability",
    Description: "The amount of money charged as VAT on your sales.",
  },
  {
    Id: "38",
    AccountID: "202238",
    AccountName: "Owner's Equity",
    AccountType: "Equity",
    Description:
      "The owners rights to the assets of a company can be quantified in the owner''s equity account.",
  },
  {
    Id: "39",
    AccountID: "202239",
    AccountName: "Parking",
    AccountType: "Expense",
    Description:
      "The parking fares you pay while on business trips can be recorded under this expense category.",
  },
  {
    Id: "40",
    AccountID: "202240",
    AccountName: "Petty Cash",
    AccountType: "Cash",
    Description:
      "It is a small amount of cash that is used to pay your minor or casual expenses rather than writing a check.",
  },
  {
    Id: "41",
    AccountID: "202241",
    AccountName: "Postage",
    AccountType: "Expense",
    Description:
      "Your expenses on ground mails, shipping and air mails can be recorded under the postage account.",
  },
  {
    Id: "42",
    AccountID: "202242",
    AccountName: "Prepaid Expenses",
    AccountType: "Other Current Asset",
    Description:
      "An asset account that reports amounts paid in advance while purchasing goods or services from a vendor.",
  },
  {
    Id: "43",
    AccountID: "202243",
    AccountName: "Printing and Stationery",
    AccountType: "Expense",
    Description:
      "Expenses incurred by the organization towards printing and stationery.",
  },
  {
    Id: "44",
    AccountID: "202244",
    AccountName: "Purchase Discounts",
    AccountType: "Expense",
    Description:
      "Tracks any reduction that your vendor offers on your purchases. Some vendors also provide them to encourage quick payment settlement.",
  },
  {
    Id: "45",
    AccountID: "202245",
    AccountName: "Rent Expense",
    AccountType: "Expense",
    Description:
      "The rent paid for your office or any space related to your business can be recorded as a rental expense.",
  },
  {
    Id: "46",
    AccountID: "202246",
    AccountName: "Repairs and Maintenance",
    AccountType: "Expense",
    Description:
      "The costs involved in maintenance and repair of assets is recorded under this account.",
  },
  {
    Id: "47",
    AccountID: "202247",
    AccountName: "Retained Earnings",
    AccountType: "Equity",
    Description:
      "The earnings of your company which are not distributed among the share holders is accounted as retained earnings.",
  },
  {
    Id: "48",
    AccountID: "202248",
    AccountName: "Salaries and Employee Wages",
    AccountType: "Expense",
    Description:
      "Salaries for your employees and the wages paid to workers are recorded under the salaries and wages account.",
  },
  {
    Id: "49",
    AccountID: "202249",
    AccountName: "Sales",
    AccountType: "Income",
    Description:
      "The income from the sales in your business is recorded under the sales account.",
  },
  {
    Id: "50",
    AccountID: "202250",
    AccountName: "Sales to Customers (Cash)",
    AccountType: "Other Current Asset",
    Description: "",
  },
  {
    Id: "51",
    AccountID: "202251",
    AccountName: "Shipping Charge",
    AccountType: "Income",
    Description:
      "Shipping charges made to the invoice will be recorded in this account.",
  },
  {
    Id: "52",
    AccountID: "202252",
    AccountName: "Speed Express Commercial Brokerage",
    AccountType: "Bank",
    Description: "",
  },
  {
    Id: "53",
    AccountID: "202253",
    AccountName: "Tag Adjustments",
    AccountType: "Other Liability",
    Description:
      "This adjustment account tracks the transfers between different reporting tags.",
  },
  {
    Id: "54",
    AccountID: "202254",
    AccountName: "Telephone Expense",
    AccountType: "Expense",
    Description:
      "The expenses on your telephone, mobile and fax usage are accounted as telephone expenses.",
  },
  {
    Id: "55",
    AccountID: "202255",
    AccountName: "Travel Expense",
    AccountType: "Expense",
    Description:
      "Expenses on business travels like hotel bookings, flight charges, etc. are recorded as travel expenses.",
  },
  {
    Id: "56",
    AccountID: "202256",
    AccountName: "Uncategorized",
    AccountType: "Expense",
    Description:
      "This account can be used to temporarily track expenses that are yet to be identified and classified into a particular category",
  },
  {
    Id: "57",
    AccountID: "202257",
    AccountName: "Undeposited Funds",
    AccountType: "Cash",
    Description:
      "Record funds received by your company yet to be deposited in a bank as undeposited funds and group them as a current asset in your balance sheet.",
  },
  {
    Id: "58",
    AccountID: "202258",
    AccountName: "Unearned Revenue",
    AccountType: "Other Current Liability",
    Description:
      "A liability account that reports amounts received in advance of providing goods or services. When the goods or services are provided, this account balance is decreased and a revenue account is increased.",
  },
  {
    Id: "59",
    AccountID: "202259",
    AccountName: "VAT Payable",
    AccountType: "Other Current Liability",
    Description: "The amount of VAT which you owe.",
  },
  {
    Id: "60",
    AccountID: "202260",
    AccountName: "Visa purchase",
    AccountType: "Expense",
    Description: "",
  },
];

export const AccountData = [
  {
    Id: "8",
    AccountName: "SpeedExpress CBD",
    AccountNumber: "1234567789",
    Iban: "56789",
    BankBalance: "15950",
    BankName: "CBD",
    SwiftCode: "123456",
    Currency: "UAE",
    Description: "CBD",
    PrimaryBank: "",
  },
  {
    Id: "9",
    AccountName: "Payment Link",
    AccountNumber: "1234567789",
    Iban: "56789",
    BankBalance: "0",
    BankName: "LINK",
    SwiftCode: "123456",
    Currency: "UAE",
    Description: "LINK",
    PrimaryBank: "",
  },
  {
    Id: "6",
    AccountName: "Airtel money",
    AccountNumber: "Airtel Money",
    Iban: "",
    BankBalance: "360",
    BankName: "AIRTEL",
    SwiftCode: "",
    Currency: "UAE",
    Description: "",
    PrimaryBank: "",
  },
  {
    Id: "12",
    AccountName: "Cash",
    AccountNumber: "Cash In Hand",
    Iban: "",
    BankBalance: "304468",
    BankName: "CASH",
    SwiftCode: "",
    Currency: "UAE",
    Description: "",
    PrimaryBank: "",
  },
  {
    Id: "14",
    AccountName: "Centenary",
    AccountNumber: "",
    Iban: "",
    BankBalance: "3100",
    BankName: "Centenary",
    SwiftCode: "",
    Currency: "AED",
    Description: "Centenary",
    PrimaryBank: "NO",
  },
  {
    Id: "3",
    AccountName: "SpeedExpress ADCB",
    AccountNumber: "1234567789",
    Iban: "56789",
    BankBalance: "7170",
    BankName: "ADCB",
    SwiftCode: "123456",
    Currency: "UAE",
    Description: "ADCB",
    PrimaryBank: "",
  },
  {
    Id: "11",
    AccountName: "Mtn money",
    AccountNumber: "Mtn money",
    Iban: "",
    BankBalance: "350",
    BankName: "MTN",
    SwiftCode: "",
    Currency: "UAE",
    Description: "",
    PrimaryBank: "",
  },
  {
    Id: "7",
    AccountName: "SpeedExpress NBDDD",
    AccountNumber: "1234567789",
    Iban: "56789",
    BankBalance: "15420",
    BankName: "NBD",
    SwiftCode: "123456",
    Currency: "UAE",
    Description: "NBD",
    PrimaryBank: "",
  },
  {
    Id: "10",
    AccountName: "Exchange",
    AccountNumber: "1234567789",
    Iban: "56789",
    BankBalance: "195",
    BankName: "EXCHANGE",
    SwiftCode: "123456",
    Currency: "UAE",
    Description: "EXCHANGE",
    PrimaryBank: "",
  },
  {
    Id: "15",
    AccountName: "Equity",
    AccountNumber: "",
    Iban: "",
    BankBalance: "0",
    BankName: "Equity",
    SwiftCode: "",
    Currency: "AED",
    Description: "Equity",
    PrimaryBank: "",
  },
];

export const ReportData = [
  { name: "Sales by Customer" },
  { name: "Sales by Item" },
  { name: "Sales by Sales Person" },
  { name: "Customer Balances" },
  { name: "Payments Received" },
  { name: "Refund History" },
];
