import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Card,
  FormControl,
  Select,
  Checkbox,
  ListItemText,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useParams } from "react-router-dom";
import ApiPath from "../../../utils/api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDataContext } from "../../../context/Context";

const ViewFlightrix = ({ data }) => {
  const {
    bankTransferData,
    customer: customerData,
    PaymentMode,
    getBankTransfer,
    itemList,
    userList,
    fetchAllData,
  } = useDataContext();
  const { Id } = useParams(); // Extracts the 'Id' parameter from the URL
  let invoiceData = data || {}; // Fallback to an empty object if data is undefined
  const [invoice, setInvoice] = useState(invoiceData);
  const [selectedModes, setSelectedModes] = useState([]); // State to store selected payment modes
  const [amounts, setAmounts] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({}); // State

  useEffect(() => {
    const getInvoiceData = async () => {
      try {
        const response = await ApiPath.get(`/invoice/flightrix/${Id}`);
        setInvoice(response.data);
      } catch (error) {
        console.error("Error fetching invoice:", error);
      }
    };
    getInvoiceData();
  }, [Id]); // Ensure data is fetched when 'Id' changes
  const downloadPDF = () => {
    const input = document.getElementById("report-content");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("report.pdf");
    });
  };
  const handleOptionChange = (mode, value, name) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [mode]: { _id: value, name: name }, // Store both the _id and name under the mode key
    }));
  };
  const handleAmountChange = (mode, value) => {
    setAmounts((prev) => ({ ...prev, [mode]: value })); // Update the amount for the specified mode
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // Convert to an array for easier handling
    const selected = typeof value === "string" ? value.split(",") : value;

    // Identify the modes being deselected
    const deselectedModes = selectedModes.filter(
      (mode) => !selected.includes(mode)
    );
    // Update the selected modes
    setSelectedModes(selected);
    // Remove amounts for deselected modes
    deselectedModes.forEach((mode) => {
      setAmounts((prevAmounts) => {
        const newAmounts = { ...prevAmounts };
        delete newAmounts[mode]; // Delete the object property for deselected mode
        return newAmounts;
      });
    });
  };
  const payBalance = async () => {
    const combined = [];
    // Iterate through the selectedOptions object
    for (const [mode, account] of Object.entries(selectedOptions)) {
      // Check if the mode exists in the amounts object
      if (amounts.hasOwnProperty(mode)) {
        // Add the mode, account name, and amount to the combined array
        combined.push({
          mode: mode.toLowerCase(),
          account: account?._id ? account._id : "", // Access the name property and convert to lowercase
          amount: amounts[mode], // Get the corresponding amount
        });
      }
    }

    const invoiceData = {
      ...invoice,
      paymentMode: combined,
    };
    // console.log("...", invoiceData);
    console.log("..invoiceData.", invoiceData);
    try {
      const response = await ApiPath.put(`/invoice/${Id}`, invoiceData);
      console.log("Invoice saved:", response.data);
      alert("Invoice submitted successfully!");
      fetchAllData();
    } catch (error) {
      console.error("Error submitting invoice:", error);
      alert("Failed to submit invoice.");
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, width: "60%", margin: "auto" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={9} id="report-content">
          <Box sx={{ p: 4 }}>
            <Box
              sx={{ mb: 4, background: "#f1e9f8", borderRadius: 2, padding: 5 }}
            >
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Box display="flex" alignItems="center">
                    <Box color="primary.main">
                      <img
                        src="https://bestoptiontravels.com/static/media/bestsv.e337dc0f8c932b77b8eef6b0d72b2442.svg"
                        height="70"
                        alt="Logo"
                      />
                    </Box>
                  </Box>
                  <Typography>
                    Al Yasat tower, Office No 603, 6th floor
                  </Typography>
                  <Typography>Najda Street, Abu dhabi</Typography>
                  <Typography>+971 2 448 941 | +971 54 410 9635</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    Invoice #{invoice?.invoiceNumber || "N/A"}
                  </Typography>
                  <Typography>
                    Date Issued: {invoice?.issuedDate || "N/A"}
                  </Typography>
                  <Typography>Date Due: {invoice?.dueDate || "N/A"}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Typography variant="h6">Invoice To:</Typography>
                <Typography>{invoice?.customer?.name || "N/A"}</Typography>
                <Typography>{invoice?.customer?.email || "N/A"}</Typography>
                <Typography>{invoice?.customer?.mobile || "N/A"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Payment Details:</Typography>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Total Due:</TableCell>
                      <TableCell>
                        AED {invoice?.total?.toFixed(2) || "0.00"}
                      </TableCell>
                    </TableRow>
                    {invoice?.paymentMode?.map((payment, index) => (
                      <TableRow key={index}>
                        <TableCell>Payment Mode:</TableCell>
                        <TableCell>
                          <span style={{ textTransform: "capitalize" }}>
                            {" "}
                            {payment?.mode}
                          </span>{" "}
                          - AED {Number(payment?.amount).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
            <TableContainer component={Paper} sx={{ mt: 4 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Passenger</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoice?.items?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>
                        AED {Number(item.price).toFixed(2) || "0.00"}
                      </TableCell>
                      <TableCell>
                        {item.firstname + " " + item.lastname}
                      </TableCell>

                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        AED{" "}
                        {(Number(item.price) * (item.quantity || 0)).toFixed(
                          2
                        ) || "0.00"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ mt: 4 }}>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  <Typography>
                    <strong>Sales Person:</strong> {invoice?.customer?.name}
                  </Typography>
                  <Typography>Thanks for your business</Typography>
                </Grid>
                <Grid item xs={6} align="right">
                  <Typography>
                    <strong>Subtotal:</strong> AED{" "}
                    {Number(invoice?.subtotal).toFixed(2) || "0.00"}
                  </Typography>
                  <Typography>
                    <strong>Discount:</strong> AED{" "}
                    {Number(invoice?.discount).toFixed(2) || "0.00"}
                  </Typography>
                  <Typography>
                    <strong>Tax:</strong> AED{" "}
                    {Number(invoice?.tax).toFixed(2) || "0.00"}
                  </Typography>
                  <Typography>
                    <strong>Balance :</strong> AED{" "}
                    {invoice?.balance?.toFixed(2)}
                  </Typography>
                  <Typography variant="h6">
                    <strong>Total:</strong> AED{" "}
                    {(invoice?.total - invoice.balance).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mt: 4 }}>
              <Typography>
                <strong>Note:</strong> {invoice?.note || "N/A"}
              </Typography>
            </Box>
          </Box>
        </Grid>

        {}

        <Grid item xs={12} lg={3}>
          <br />

          <Card sx={{ p: 3 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<SendIcon />}
              sx={{ mb: 2 }}
              onClick={() => console.log("Send Invoice")}
            >
              Send Invoice
            </Button>
            <Button
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              onClick={() => console.log("Preview Invoice")}
            >
              Print
            </Button>
            <Button variant="outlined" fullWidth onClick={() => downloadPDF()}>
              Save
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewFlightrix;
