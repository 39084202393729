import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  TextField,
  Box,
} from "@mui/material";
import { DateRangePicker } from "@mui/lab";

const ProfitAndLossPage = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [revenue, setRevenue] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [profitLoss, setProfitLoss] = useState(0);

  useEffect(() => {
    // Dummy data for revenue and expenses
    const revenueData = [
      { id: 1, description: "Product Sales", amount: 15000 },
      { id: 2, description: "Service Income", amount: 8000 },
      { id: 3, description: "Interest Income", amount: 500 },
    ];
    const expenseData = [
      { id: 1, description: "Cost of Goods Sold", amount: 7000 },
      { id: 2, description: "Salaries", amount: 5000 },
      { id: 3, description: "Rent", amount: 2000 },
      { id: 4, description: "Utilities", amount: 500 },
      { id: 5, description: "Office Supplies", amount: 300 },
    ];

    setRevenue(revenueData);
    setExpenses(expenseData);

    // Calculate profit and loss based on revenue and expenses
    const totalRevenue = revenueData.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    const totalExpenses = expenseData.reduce(
      (acc, item) => acc + item.amount,
      0
    );
    setProfitLoss(totalRevenue - totalExpenses);
  }, [dateRange]);

  return (
    <Container sx={{ flex: 1, mt: 5 }}>
      <div style={{ width: "70%", margin: "auto" }}>
        <Typography variant="h4" gutterBottom>
          Profit and Loss
        </Typography>

        <Box sx={{ mb: 3 }}>
          <DateRangePicker
            startText="Start Date"
            endText="End Date"
            value={dateRange}
            onChange={(newRange) => setDateRange(newRange)}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </>
            )}
          />
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Category</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Description</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Amount (AED)</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Revenue Section */}
            <TableRow>
              <TableCell rowSpan={revenue.length + 1}>
                <strong>Revenue</strong>
              </TableCell>
            </TableRow>
            {revenue.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="right">{item.description}</TableCell>
                <TableCell align="right">{item.amount.toFixed(2)}</TableCell>
              </TableRow>
            ))}

            {/* Expenses Section */}
            <TableRow>
              <TableCell rowSpan={expenses.length + 1}>
                <strong>Expenses</strong>
              </TableCell>
            </TableRow>
            {expenses.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="right">{item.description}</TableCell>
                <TableCell align="right">{item.amount.toFixed(2)}</TableCell>
              </TableRow>
            ))}

            {/* Net Profit */}
            <TableRow>
              <TableCell colSpan={2}>
                <strong>Net Profit</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{profitLoss.toFixed(2)}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Container>
  );
};

export default ProfitAndLossPage;
