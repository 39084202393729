import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

const RecurringBillPage = () => {
  const [open, setOpen] = useState(false);
  const [bills, setBills] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Define columns for the DataGrid with `flex: 1` for responsive design
  const columns = [
    { field: "name", headerName: "Bill Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "amount", headerName: "Amount (AED)", flex: 1 },
    { field: "frequency", headerName: "Frequency", flex: 1 },
    { field: "startDate", headerName: "Start Date", flex: 1 },
    { field: "endDate", headerName: "End Date", flex: 1 },
    { field: "paymentMethod", headerName: "Payment Method", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <Tooltip title="Delete Bill">
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const newBill = {
      id: bills.length + 1,
      name: formData.get("name"),
      description: formData.get("description"),
      amount: formData.get("amount"),
      frequency: formData.get("frequency"),
      startDate: formData.get("startDate"),
      endDate: formData.get("endDate"),
      paymentMethod: formData.get("paymentMethod"),
    };
    setBills((prevBills) => [...prevBills, newBill]);
    handleClose();
  };

  const handleDelete = (id) => {
    setBills((prevBills) => prevBills.filter((bill) => bill.id !== id));
  };

  const handleClearAll = () => {
    setBills([]);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Recurring Bills
      </Typography>

      <Box display="flex" justifyContent="space-between" mb={2}>
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Add Recurring Bill
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClearAll}
          disabled={bills.length === 0}
        >
          Clear All Bills
        </Button>
      </Box>

      <Box sx={{ height: 400 }}>
        <DataGrid rows={bills} columns={columns} pageSize={5} />
      </Box>

      {/* Modal for Adding Recurring Bill */}
      <Modal open={open} onClose={handleClose}>
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
            borderRadius: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Add New Recurring Bill</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            name="name"
            label="Bill Name"
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            name="description"
            label="Description"
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            name="amount"
            label="Amount (AED)"
            type="number"
            fullWidth
            required
            sx={{ mb: 2 }}
          />
          <TextField
            name="frequency"
            label="Frequency"
            select
            fullWidth
            required
            defaultValue="Monthly"
            sx={{ mb: 2 }}
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Quarterly">Quarterly</MenuItem>
            <MenuItem value="Annually">Annually</MenuItem>
          </TextField>
          <TextField
            name="startDate"
            label="Start Date"
            type="date"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            name="endDate"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
          <TextField
            name="paymentMethod"
            label="Payment Method"
            select
            fullWidth
            required
            defaultValue="Credit Card"
            sx={{ mb: 2 }}
          >
            <MenuItem value="Credit Card">Credit Card</MenuItem>
            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
            <MenuItem value="Cash">Cash</MenuItem>
          </TextField>

          <Button type="submit" variant="contained" color="primary" fullWidth>
            Save
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default RecurringBillPage;
