import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Grid,
  FormControl,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDataContext } from "../../context/Context";
import RowOptions from "../../components/rowOption";
import RowOptionBank from "../../components/rowOptionBank";
import { Link } from "react-router-dom";
import { formatNumber } from "../../components/formatNumber";

const columns = (handleClick, handleClose, anchorEl, open) => [
  {
    field: "mode",
    headerName: "Type",
    flex: 1,
    renderCell: (params) => (
      <span style={{ textTransform: "uppercase" }}>{params.value}</span>
    ),
  },
  {
    field: "bankName",
    headerName: "Bank Name",
    flex: 0.8,
    renderCell: (params) => (
      <span style={{ textTransform: "uppercase" }}>{params.value}</span>
    ),
  },
  {
    field: "accountName",
    headerName: "Account Name",
    flex: 1.3,
    renderCell: (params) => (
      <span style={{ textTransform: "uppercase" }}>{params.value}</span>
    ),
  },
  { field: "accountNumber", headerName: "Account Number", flex: 1 },
  {
    field: "balance",
    headerName: "Balance",
    flex: 1,
    renderCell: (params) => <span> {formatNumber(params.value)}</span>,
  },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    width: 200,
    renderCell: (row) => <RowOptionBank id={row._id} data={row} />,
  },
];

const BankList = () => {
  const { bankTransferData, getBankTransfer } = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    getBankTransfer();
  }, []);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  return (
    <Box
      className="page pagexx banklist"
      component="form"
      noValidate
      style={{ background: "white", padding: 20, height: "90vh" }}
    >
      <div>
        <div className="manageaudit">
          <ul>
            <li style={{ background: " " }}>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ marginRight: "8px" }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                />

                <FormControl sx={{ m: 1, width: "40%" }}>
                  {/* <InputLabel>Salutation</InputLabel> */}
                  <Select
                    style={{ height: 46 }}
                    name="salutation"
                    value="All Banks"
                  >
                    <MenuItem value="All Banks">All Banks</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </li>

            <li>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Link to="/banks/addBank">
                  <Button
                    className="addbutton"
                    align="center"
                    style={{ width: "150px", marginRight: "8px" }}
                    variant="contained"
                  >
                    ADD ACCOUNT
                  </Button>
                </Link>
                <Link to="/banks/statements">
                  <Button
                    className="addbutton"
                    variant="contained"
                    style={{ color: "black", background: "#ffe5d9" }}
                  >
                    Statements
                  </Button>
                </Link>
              </div>
            </li>
            <li align="right" style={{ marginTop: "16px" }}>
              <div
                className="nawdashLabel"
                style={{
                  paddingTop: "2px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: " #e4b7b7",
                    color: "#a01c1c",
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#d1ddf7",
                    color: "#093240",
                    border: "none",
                  }}
                >
                  PRINT
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#c6f6d1",
                    color: " #0a6323",
                    border: "none",
                  }}
                >
                  CSV
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <DataGrid
        rows={bankTransferData}
        columns={columns(handleClick, handleClose, anchorEl, open)}
        pageSize={2} // Set page size to 2 rows
        rowsPerPageOptions={[2, 5, 10]} // Pagination options
        pagination // Add this to enable pagination
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row) => row._id} // Ensure that the ID is unique
        sx={{
          marginTop: 2,
          border: "none",
          "& .MuiDataGrid-cell": {
            border: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "grey",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "black",
          },
        }}
      />
    </Box>
  );
};

export default BankList;
