import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  Autocomplete,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useDataContext } from "../../../context/Context";
import ApiPath from "../../../utils/api";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { Accounts } from "../../../lib/data";
import axios from "axios";

export default function AddExpenseForm({ data }) {
  const navigate = useNavigate();
  const { fetchAllData, bankTransferData, PaymentMode } = useDataContext();
  const [issuedDate, setIssuedDate] = useState(data?.issuedDate || "");
  const [account, setAccount] = useState(data?.expenseAccount || "");
  const [amount, setAmount] = useState(data?.amount || "");
  const [vendor, setVendor] = useState(data?.vendor || "");
  const [reference, setReference] = useState(data?.reference || "");
  const [customerName, setCustomerName] = useState(data?.customerName || "");
  const [mode, setMode] = useState(data?.mode || "");
  const [narration, setNarration] = useState(data?.narration || "");
  const [file, setFile] = useState(null);
  const [UploadedFile, setUploadedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedModes, setSelectedModes] = useState([]);
  const [amounts, setAmounts] = useState(data?.amounts || {});
  const [loadingImage, setloadingImage] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    data?.selectedOptions || {}
  );

  const handleAmountChange = (mode, value) => {
    setAmounts((prev) => ({ ...prev, [mode]: value }));
  };

  const handleOptionChange = (mode, value, name) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [mode]: { _id: value, name: name },
    }));
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const selected = typeof value === "string" ? value.split(",") : value;
    const deselectedModes = selectedModes.filter(
      (mode) => !selected.includes(mode)
    );
    setSelectedModes(selected);
    // console.log("slected", deselectedModes);
    deselectedModes.forEach((mode) => {
      setAmounts((prevAmounts) => {
        const newAmounts = { ...prevAmounts };
        delete newAmounts[mode];
        return newAmounts;
      });
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const combined = [];
    for (const [mode, account] of Object.entries(selectedOptions)) {
      if (amounts.hasOwnProperty(mode)) {
        combined.push({
          mode: mode.toLowerCase(),
          account: account?._id ? account?._id : "",
          amount: amounts[mode],
        });
      }
    }

    const expenseData = {
      expenseNo: data?.expenseNo || Math.floor(100000 + Math.random() * 900000),
      expenseAccount: account,
      amount: amount,
      vendor: vendor,
      reference: reference,
      customerName: customerName,
      mode: mode,
      issuedDate: issuedDate,
      narration: narration,
      paymentMode: combined,
      file: UploadedFile ? UploadedFile : null,
      ...(data?._id && { _id: data?._id }), // Add _id only if data?._id exists
    };
    try {
      setLoading(true);
      const response = data?._id
        ? await ApiPath.put(`/expense/${data?._id}`, expenseData)
        : await ApiPath.post(`/expense`, expenseData);
      console.log("response", response);
      fetchAllData();
      message.success("Expense Saved Successfully");
      navigate("/expenses/list");
    } catch (error) {
      console.error(error);
      alert("Failed to submit the expense. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  //  const handleFileChangexxx = async (
  //    file,
  //    categoryIndex,
  //    subIndex,
  //    productIndex
  //  ) => {
  //    setloadingImage(productIndex);

  //    // Check if the file is selected
  //    if (!file) {
  //      console.log("No file selected");
  //      return; // Exit if no file is selected
  //    }
  //    console.log("File selected: ", file);
  //    const formData = new FormData();
  //    formData.append("image", file, file.name); // Ensure key matches PHP backend ('image')
  //    try {
  //      // Upload the image and wait for the response
  //      const response = await axios.post(
  //        "https://admin.tidyhub.ae/upload.php",
  //        formData,
  //        {
  //          headers: {
  //            "Content-Type": "multipart/form-data",
  //          },
  //          withCredentials: true, // Required for CORS with credentials
  //        }
  //      );
  //      // Extract the image URL from the response
  //      const imageUrl = response.data.filePath; // Match the response structure from PHP
  //      // Update the state with the image URL
  //      const updatedCategories = [...categories];
  //      updatedCategories[categoryIndex].subcategories[subIndex].products[
  //        productIndex
  //      ].image = imageUrl;
  //      setCategories(updatedCategories);
  //      console.log("Updated categories: ", updatedCategories);
  //    } catch (error) {
  //      console.error("Error uploading file: ", error);
  //    } finally {
  //      // Reset the loading state
  //      setloadingImage(null);
  //    }
  //  };

  const handleFileChange = async (event) => {
    setloadingImage(true);
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    setFile(selectedFile);
    // Prepare the file for upload
    const formData = new FormData();
    formData.append("image", selectedFile);
    try {
      // Upload the image and wait for the response
      const response = await axios.post(
        "https://admin.tidyhub.ae/uploadsystem.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true, // Required for CORS with credentials
        }
      );
      // Extract the image URL from the response
      const imageUrl = response.data.filePath; // Match the response structure from PHP
      // Update the state with the image URL
      setUploadedFile(imageUrl);
      console.log("imageUrl", imageUrl);
      // alert("File uploaded successfully");
    } catch (error) {
      console.error("Error uploading file: ", error);
    } finally {
      // Reset the loading state
      // setloadingImage(null);
    }
    setloadingImage(false);
  };

  return (
    <form
      style={{ maxWidth: "400px", margin: "auto", paddingTop: 20 }}
      onSubmit={handleSubmit}
    >
      <h2>{data ? "EDIT EXPENSE" : "ADD EXPENSE"}</h2>
      <TextField
        fullWidth
        type="date"
        size="small"
        value={issuedDate}
        onChange={(e) => setIssuedDate(e.target.value)}
      />

      {!data?._id && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Expense Account</InputLabel>
          <Select
            value={account}
            onChange={(e) => setAccount(e.target.value)}
            label="Expense Account"
          >
            {Accounts.map((a, index) => (
              <MenuItem key={index} value={a.AccountName}>
                {a.AccountName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <TextField
        fullWidth
        margin="normal"
        label="Amount"
        variant="outlined"
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Vendor"
        variant="outlined"
        value={vendor}
        onChange={(e) => setVendor(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Reference"
        variant="outlined"
        value={reference}
        onChange={(e) => setReference(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Customer Name"
        variant="outlined"
        value={customerName}
        onChange={(e) => setCustomerName(e.target.value)}
      />

      {!data?._id && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth style={{ marginTop: 15 }}>
              <Select
                multiple
                value={selectedModes}
                onChange={handleChange}
                size="small"
                renderValue={(selected) => selected.join(", ")}
              >
                {PaymentMode.map(({ mode }) => (
                  <MenuItem key={mode} value={mode}>
                    <Checkbox checked={selectedModes.indexOf(mode) > -1} />
                    <ListItemText primary={mode} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {selectedModes.map((mode) => {
            const { options } = PaymentMode.find((m) => m.mode === mode) || {};
            return (
              <Grid container item xs={12} spacing={1} key={mode}>
                <Grid
                  item
                  xs={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    variant="body1"
                    style={{ textTransform: "capitalize" }}
                  >
                    {mode}:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {options && options.length > 0 && (
                    <FormControl fullWidth required>
                      <Autocomplete
                        options={options}
                        getOptionLabel={(subOption) =>
                          `${subOption.name} (Account: ${subOption.account})`
                        }
                        onChange={(event, newValue) => {
                          handleOptionChange(
                            mode,
                            newValue ? newValue?._id : "",
                            newValue ? newValue.name : ""
                          );
                        }} // Update selected option
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Option"
                            size="small"
                            variant="outlined"
                            required
                          />
                        )}
                        value={
                          options.find(
                            (subOption) =>
                              subOption?._id === selectedOptions[mode]?._id
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?._id === value?._id
                        } // Comparison function by _id
                        fullWidth
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    type="number"
                    placeholder="Enter amount"
                    value={amounts[mode] || ""} // Controlled input for amount
                    onChange={(e) => handleAmountChange(mode, e.target.value)} // Update amount
                    style={{ width: "100%" }} // Full width
                    required
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
      <TextField
        fullWidth
        margin="normal"
        label="Narration"
        variant="outlined"
        multiline
        rows={3}
        value={narration}
        onChange={(e) => setNarration(e.target.value)}
      />
      {loadingImage ? (
        <span>Uploading image....</span>
      ) : (
        <Button
          variant="outlined"
          component="label"
          fullWidth
          style={{ marginTop: "16px", marginBottom: "16px" }}
        >
          Attach Document
          <input type="file" hidden onChange={handleFileChange} />
        </Button>
      )}

      <div>
        {/* {UploadedFile} */}
        <div align="center">
          {UploadedFile && (
            <img
              src={UploadedFile}
              alt="Uploaded File"
              style={{ width: "140px", height: "auto" }}
            />
          )}
        </div>
      </div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        disabled={loading}
      >
        {loading ? "Saving..." : "Save"}
      </Button>
    </form>
  );
}
