import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Box,
  IconButton,
  MenuItem,
  Menu,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDataContext } from "../../context/Context";
import "./styles.css";
import RowOptions from "../../components/rowOption";
import { Link } from "react-router-dom";
import SummaryPanel from "../../components/topsection";
import { formatNumber } from "../../components/formatNumber";

const options = { day: "2-digit", month: "2-digit", year: "numeric" };

const columns = (handleClick, handleClose, anchorEl, open) => [
  {
    field: "updatedAt",
    headerName: "DATE",
    flex: 1.4,
    // valueGetter: (params) => new Date(params).toLocaleString(),
    renderCell: (params) => (
      <span style={{}}>
        {new Date(params.row.updatedAt).toLocaleString("en-GB")}
      </span>
    ),
  },
  {
    field: "invoiceNumber",
    headerName: "INVOICE",
    flex: 1.2,
    renderCell: (params) => (
      <Link to={`/sales/view/${params.row._id}`} style={{}}>
        <span>INV#{params.row.invoiceNumber}</span>
      </Link>
    ),
  },
  {
    field: "customer",
    headerName: "CUSTOMER NAME",
    flex: 1.6,
    valueGetter: (params) => params?.name || "N/A",
  },
  {
    field: "items",
    headerName: "ITEMS",
    flex: 0.7,
    valueGetter: (params) => params?.length || "N/A",
  },
  {
    field: "total",
    headerName: "AMOUNT",
    flex: 0.7,
    valueGetter: (params) => formatNumber(params) + " AED" || "N/A",
  },
  {
    field: "dueDate",
    headerName: "DUE DATE",
    flex: 0.7,
  },
  {
    field: "status",
    headerName: "STATUS",
    flex: 0.9,
    renderCell: (params) => (
      <span
        style={{
          background:
            params.row.balance === params.row.total
              ? "pink"
              : params.row.balance > 0
                ? "orange"
                : "#edffe0",
          padding: 10,
          paddingLeft: 15,
          paddingRight: 15,
          borderRadius: 10,
          fontSize: 12,
        }}
      >
        {params.row.balance === params.row.total
          ? "Not Paid"
          : params.row.balance > 0
            ? "Half Paid"
            : "Paid"}
      </span>
    ),
  },
  {
    field: "issuer",
    headerName: "ISSUED BY",
    flex: 0.7,
    renderCell: (params) => (
      <span style={{ textTransform: "capitalize" }}>{params.row.issuer}</span>
    ),
  },
  {
    field: "actions",
    headerName: "ACTIONS",
    sortable: false,
    width: 100,
    renderCell: (row) => <RowOptions id={row._id} data={row} />,
  },
];

function InvoiceVoidedList() {
  const { invoiceList: rows } = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const open = Boolean(anchorEl);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };
  const handleSelectionChange = (selectionModel) => {
    setSelectedRows(selectionModel); // Update the state with selected row IDs
    console.log("SELECTEDROW", selectedRows);
  };
  const handleCreatePurchase = () => {
    console.log("Selected rows for purchase:", selectedRows);
  };

  const dataRow = rows.filter((row) => row?.exp == "Void");
 

  const filteredRows = dataRow
    .filter(
      (row) =>
        String(row.invoiceNumber)
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) || // Convert invoiceNumber to string and perform case-insensitive match
        row.customer?.name?.toLowerCase().includes(searchQuery.toLowerCase()) || // Case-insensitive match for customer name
        String(row.total).includes(searchQuery) // Match on total if needed
    )
    .sort((a, b) => {
      const dateA = new Date(a.createdAt); // Convert the ISO date string to a Date object
      const dateB = new Date(b.createdAt);
      return dateB - dateA; // Sort from earliest to latest
    });
  return (
    <Box
      style={{
        padding: 20,
      }}
      className="invoicelist"
    >
      <SummaryPanel />
      <div style={{ padding: 0 }}>
        <div className="manageaudit" style={{}}>
          <ul style={{ padding: 0, margin: 0 }}>
            <li style={{}}>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ marginRight: "8px" }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                  value={searchQuery} // Set value of TextField
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <Link to="/sales/invoice/add">Voide invoice</Link>
              </div>
            </li>

            <li>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                {selectedRows.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreatePurchase}
                    style={{ background: "brown" }}
                  >
                    Create Purchase ({selectedRows.length} selected)
                  </Button>
                )}
              </div>
            </li>
            <li align="right" style={{ marginTop: "16px" }}>
              <div
                className="nawdashLabel"
                style={{
                  paddingTop: "2px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: " #e4b7b7",
                    color: "#a01c1c",
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#d1ddf7",
                    color: "#093240",
                    border: "none",
                  }}
                >
                  PRINT
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#c6f6d1",
                    color: " #0a6323",
                    border: "none",
                  }}
                >
                  CSV
                </Button>
                <FormControl sx={{ m: 1, width: "40%" }}>
                  {/* <InputLabel>Salutation</InputLabel> */}
                  <Select
                    style={{ height: 46 }}
                    name="salutation"
                    value="Select Status"
                    // value={formData.salutation}
                    // onChange={handleInputChange}
                  >
                    <MenuItem value="Select Status">Select Status</MenuItem>
                    <MenuItem value="All Banks">Downloaded</MenuItem>
                    <MenuItem value="Ms">Draft</MenuItem>
                    <MenuItem value="Mrs">Paid</MenuItem>
                    <MenuItem value="Mrs">Patial Payment</MenuItem>
                    <MenuItem value="Mrs">Passed Due</MenuItem>
                    <MenuItem value="Mrs">Sent</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <Box
        sx={{
          width: "100%",
          marginTop: 4,
          // p: isSmallScreen ? 2 : 3,
          background: "white",
          // boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          borderRadius: 1,
        }}
      >
        <DataGrid
          rows={filteredRows} // Use filtered rows
          columns={columns(handleClick, handleClose, anchorEl, open)}
          pageSize={10}
          // rowsPerPageOptions={[10, 25, 50]}
          rowsPerPageOptions={[10, 25, 50]}
          pageSizeOptions={[10, 25, 50, 100]} // Compatibility with latest MUI versions
          paginationMode="client"
          rowCount={filteredRows.length}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row._id}
          onRowSelectionModelChange={(newSelectionModel) =>
            handleSelectionChange(newSelectionModel)
          }
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{
            marginTop: 2,
            border: "none",
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "grey",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "black",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default InvoiceVoidedList;
