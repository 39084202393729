import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Box,
  IconButton,
  MenuItem,
  Menu,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDataContext } from "../../context/Context";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import RowOptions from "../../components/rowOption";
import SummaryPanel from "../../components/topsection";
import { formatNumber } from "../../components/formatNumber";
import { message } from "antd";

// Column configuration
const columns = (handleClick, handleClose, anchorEl, open) => [
  {
    field: "createdAt",
    headerName: "DATE",
    flex: 1,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{params.row.createdAt}</div>
        <div style={{ fontSize: "12.5px" }}>
          {params.row.createdAtTime}
        </div>{" "}
        {/* Changed to params.row.name */}
      </div>
    ),
  },
  {
    field: "transNo",
    headerName: "TRANS NO",
    flex: 1,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div> TX#{params.row.transNo}</div>
        <div style={{ color: "#bf7fff", fontWeight: "bold" }}>
          <Link to={`/sales/view/${params.row.invoiceIdNumber}`}>
            INV#{params.row.invoiceId}
          </Link>
        </div>{" "}
        {/* Changed to params.row.name */}
      </div>
    ),
  },
  {
    field: "name",
    headerName: "PASSENGER",
    flex: 1.4,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{params.row.name}</div>
        <div style={{ fontSize: "12.5px" }}>
          {params.row.nationality},<b>{params.row.passport}</b>{" "}
        </div>{" "}
        {/* Changed to params.row.name */}
      </div>
    ),
  },
  {
    field: "itemData",
    headerName: "ITEM",
    flex: 1.2,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{splitTextBefore(params.row.itemData)}</div>
        <div style={{ fontSize: "12.5px" }}>
          {splitTextAfter(params.row.itemData)}
        </div>{" "}
        {/* Changed to params.row.name */}
      </div>
    ),
  },
  {
    field: "x",
    headerName: "CLIENT",
    flex: 1.2,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{params.row.taggedUser}</div>
      </div>
    ),
  },
  {
    field: "price",
    headerName: "AMOUNT",
    flex: 1,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ color: "green", fontWeight: "bold" }}>
          {formatNumber(params.row.price)} AED
        </div>
        <div style={{ color: "#ff4d49", fontWeight: "bold" }}>
          {params.row.purchased ? (
            <span>{params.row.purchasedAmount} AED</span>
          ) : (
            ""
          )}
        </div>{" "}
      </div>
    ),
  },
  {
    field: "Xx",
    headerName: "STATUS",
    flex: 1.2,
    renderCell: (params) => (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <span
          style={{
            background: params.row.purchased == true ? "pink" : "#edffe0",
            padding: 10,
            paddingLeft: 15,
            paddingRight: 15,
            borderRadius: 10,
            fontSize: 12,
          }}
        >
          {params.row.purchased == true ? "Billed" : "Not Billed"}
        </span>
      </div>
    ),
  },
  {
    field: "actions",
    headerName: "ACTIONS",
    sortable: false,
    width: 200,
    renderCell: ({ row }) => (
      <RowOptions id={row._id} data={row} link={row.invoiceIdNumber} />
    ),
  },
];

function splitTextBefore(text) {
  const [before, after] = text.split("|").map((part) => part.trim());
  return before;
}
function splitTextAfter(text) {
  const [before, after] = text.split("|").map((part) => part.trim());
  return after;
}

const TransactionList = () => {
  const { invoiceList } = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowIds, setSelectedRowIds] = useState([]); // Track selected row IDs
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  let { transactionList: transactionData } = useDataContext();
  const navigate = useNavigate(); // Initialize useNavigate
  const [selectedRows, setSelectedRows] = useState([]); // State to track selected rows
  const [uniqueTaggedUserCount, setUniqueTaggedUserCount] = useState(0);
  // const { invoiceList } = useDataContext();

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
  };
  const [totalPrice, setTotalPrice] = useState(0);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterInvoice = (_idIndex) => {
    const filteredData = invoiceList.find((item) => item._id === _idIndex);
    console.log("gshsjd", filteredData);
    return filteredData ? filteredData.invoiceNumber : null;
  };
  // Handle selection changes
  const handleSelectionChange = (selectionModel) => {
    setSelectedRowIds(selectionModel); // Update selected row IDs
    const filteredData = transactionData.filter((item) =>
      selectionModel.includes(item._id)
    );
    setSelectedRows(filteredData);
  };

  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  // Prepare rows based on the sample JSON

  // const voided = [
  //   { id: "67851b0f28f165969046eca0" },
  //   { id: "67851bd028f165969046eff1" },
  // ];

  const voided = invoiceList.filter((row) => row?.exp == "Void");

  const dataRow = transactionData.filter(
    (row) =>
      row?.exp !== "Void" &&
      !voided.some((voidedItem) => voidedItem._id === row.invoiceId)
  );

  const rows = dataRow
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map((transaction, index) => ({
      id: index + 1,
      _id: transaction._id,
      name: `${transaction.firstname} ${transaction.lastname}`,
      itemData: transaction.item,
      price: transaction.price,
      passport: transaction.passport,
      nationality: transaction.nationality,
      createdAt: new Date(transaction.createdAt).toLocaleDateString(
        "en-GB",
        options
      ),
      createdAtTime: new Date(transaction.createdAt).toLocaleTimeString(),
      transNo: transaction.transNo,
      invoiceId: filterInvoice(transaction.invoiceId),
      invoiceIdNumber: transaction.invoiceId,
      taggedUser: transaction.taggedUser,
      purchased: transaction.purchased,
      purchasedAmount: transaction.purchasedAmount,
    }));

  useEffect(() => {
    const total = rows.reduce((acc, transaction) => acc + transaction.price, 0);
    setTotalPrice(total);
    const uniqueUsers = new Set(
      rows.map((transaction) => transaction.taggedUser)
    );
    setUniqueTaggedUserCount(uniqueUsers.size);
  }, [transactionData]);

  const handleCreatePurchase = () => {
    const billedRows = selectedRows.filter((row) => row.purchased); // Find rows where billed is true

    if (billedRows.length > 0) {
      message.error("Some items have already been billed and will be ignored.");
    }

    const modifiedRows = selectedRows
      .filter((row) => !row.purchased) // Exclude rows where billed is true
      .map(({ item, ...rest }) => ({
        ...rest,
        name: item,
      }));

    navigate("/purchases/add", {
      state: { selectedRows: modifiedRows },
    });
  };

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 9,
  });
  return (
    <Box
      style={{
        padding: 20,
      }}
      className="transactionlist"
    >
      <SummaryPanel
        total={rows.length}
        totalPrice={totalPrice}
        uniqueTaggedUserCount={uniqueTaggedUserCount}
      />
      <div style={{ padding: 0 }}>
        <div className="manageaudit" style={{}}>
          <ul style={{ padding: 0, margin: 0 }}>
            <li style={{}}>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ marginRight: "8px" }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                  // value={searchQuery} // Set value of TextField
                  // onChange={(e) => setSearchQuery(e.target.value)}
                />

                <Link to="/sales/invoice/add">
                  <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      marginLeft: isSmallScreen ? 0 : 2,
                      mt: isSmallScreen ? 2 : 0,
                      height: "50px",
                      width: isSmallScreen ? "100%" : "auto",
                      backgroundColor: "#0597b1",
                    }}
                    className="buttoncolor"
                  >
                    Add Invoice
                  </Button>
                </Link>
              </div>
            </li>

            <li>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                {selectedRows.length > 0 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreatePurchase}
                    style={{ background: "brown" }}
                  >
                    Create Purchase ({selectedRows.length} selected)
                  </Button>
                )}
              </div>
            </li>
            <li align="right" style={{ marginTop: "16px" }}>
              <div
                className="nawdashLabel"
                style={{
                  paddingTop: "2px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: " #e4b7b7",
                    color: "#a01c1c",
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#d1ddf7",
                    color: "#093240",
                    border: "none",
                  }}
                >
                  PRINT
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#c6f6d1",
                    color: " #0a6323",
                    border: "none",
                  }}
                >
                  CSV
                </Button>
                <FormControl sx={{ m: 1, width: "40%" }}>
                  {/* <InputLabel>Salutation</InputLabel> */}
                  <Select
                    style={{ height: 46 }}
                    name="salutation"
                    value="Select Status"
                  >
                    <MenuItem value="Select Status">Select Status</MenuItem>
                    <MenuItem value="All Banks">Downloaded</MenuItem>
                    <MenuItem value="Ms">Draft</MenuItem>
                    <MenuItem value="Mrs">Paid</MenuItem>
                    <MenuItem value="Mrs">Patial Payment</MenuItem>
                    <MenuItem value="Mrs">Passed Due</MenuItem>
                    <MenuItem value="Mrs">Sent</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Box
        sx={{
          width: "100%",
          // marginTop: 4,
          // p: isSmallScreen ? 2 : 3,
          background: "white",
          // boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          borderRadius: 1,
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns(handleClick, handleClose, anchorEl, open)}
          // pageSize={5}
          // rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          getRowHeight={() => "auto"}
          // pageSizeOptions={[10, 25, 50]}
          rowsPerPageOptions={[10, 25, 50]}
          pageSizeOptions={[10, 25, 50, 100]} // Compatibility with latest MUI versions
          getRowId={(rows) => rows._id}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          onRowSelectionModelChange={(newSelectionModel) =>
            handleSelectionChange(newSelectionModel)
          }
          sx={{
            marginTop: 2,
            border: "none",
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "black",
              // background: "white",
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default TransactionList;
