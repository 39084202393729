import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DashboardLayoutBasic from "./sections/Index";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Cookies from "js-cookie";
import "./App.css";
import Login from "./sections/Auth/Login";
import ViewFlightrix from "./sections/sales/Sections/ViewFlightrix";
import ProtectedRoute from "./components/protected";

function App() {
  // Create a custom MUI theme
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976d2",
      },
      // Customize your primary color
      secondary: {
        main: "#dc004e",
      },
      // Customize your secondary color
    },
    typography: {
      fontFamily: "CustomFont",
      fontSize: 12.5,
    },
    // Customize more theme properties as needed
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route
            path="/flightrix/view/:Id"
            element={
              <ProtectedRoute>
                <ViewFlightrix />
              </ProtectedRoute>
            }
          />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <DashboardLayoutBasic />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
