// BankContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import ApiPath from "../utils/api";
import Cookies from "js-cookie";

const dataContext = createContext();
export const useDataContext = () => {
  return useContext(dataContext);
};

export const ContextProvider = ({ children }) => {
  const [bankTransferData, setBank] = useState([]);
  const [PaymentMode, setPayment] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [userList, setUser] = useState([]);
  const [paymentRecieved, setPaymentRecieved] = useState([]);
  const [PaymentRefunds, setPaymentRefunds] = useState([]);
  const [bankingHistory, setBankingHistoryList] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [expense, setExpenseList] = useState([]);
  const [customer, setCustomerList] = useState([]);
  const [vendors, setVendorsList] = useState([]);
  const [purc, setPurcList] = useState([]);
  const [id, setId] = useState(Cookies.get("_id") || null);
  // const [customer, setCustomer] = useState([]);
  const getBankTransfer = async () => {
    try {
      const response = await ApiPath.get("/bank");
      const PaymentMode = Object.values(
        response?.data.reduce((acc, item) => {
          const { mode, accountName, accountNumber, balance, _id } = item;

          if (!acc[mode]) {
            acc[mode] = { mode, options: [] };
          }
          acc[mode].options.push({
            _id: _id, // Use _id for unique identification
            name: accountName, // Account name (for display)
            account: accountNumber, // Account number
            balance, // Account balance
          });
          return acc;
        }, {})
      );
      setBank(response.data);
      setPayment(PaymentMode);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
    }
  };
  const getInvoiceList = async () => {
    try {
      const response = await ApiPath.get("/invoice");
      setInvoiceList(response.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      // alert("Failed to fetch invoices.");
    }
  };
  const getUser = async () => {
    try {
      const response = await ApiPath.get("/users");
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
      // alert("Failed to fetch invoices.");
    }
  };
  const getPaymentRecieved = async () => {
    try {
      const response = await ApiPath.get("/paymentRecieved");
      setPaymentRecieved(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch bank transfers.");
    }
  };
  const getPaymentRefunds = async () => {
    try {
      const response = await ApiPath.get("/paymentRecieved/refunds");
      setPaymentRefunds(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch bank transfers.");
    }
  };
  const getBankingHistory = async () => {
    try {
      const response = await ApiPath.get("/bankTransfer");
      setBankingHistoryList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch transfering.");
    }
  };
  const getCustomerList = async () => {
    try {
      const response = await ApiPath.get("/customer");
      setCustomerList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch.");
    }
  };
  const getVendorList = async () => {
    try {
      const response = await ApiPath.get("/vendor");
      setVendorsList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch.");
    }
  };
  const getItemList = async () => {
    try {
      const response = await ApiPath.get("/item");
      setItemList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch bank transfers.");
    }
  };
  const getExpenseList = async () => {
    try {
      const response = await ApiPath.get("/expense");
      setExpenseList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch bank transfers.");
    }
  };
  const getPurchaseList = async () => {
    try {
      const response = await ApiPath.get("/purchase");
      setPurcList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch bank transfers.");
    }
  };
  const getTransactionList = async () => {
    try {
      const response = await ApiPath.get("/transaction");
      setTransactionList(response.data);
    } catch (error) {
      console.error("Error fetching bankTransfer:", error);
      // alert("Failed to fetch bank transfers.");
    }
  };

  const fetchAllData = () => {
    getBankingHistory();
    getBankTransfer();
    getInvoiceList();
    getPaymentRecieved();
    getPurchaseList();
    getExpenseList();
    getItemList();
    getCustomerList();
    getTransactionList();
    getUser();
    getVendorList();
  };

  useEffect(() => {
    if (id) {
      getBankingHistory();
      getBankTransfer();
      getInvoiceList();
      getPaymentRecieved();
      getPaymentRefunds();
      getPurchaseList();
      getExpenseList();
      getVendorList();
      getItemList();
      getCustomerList();
      getTransactionList();
      getUser();
    }
  }, []);

  return (
    <dataContext.Provider
      value={{
        bankTransferData,
        expense,
        getExpenseList,
        itemList,
        purc,
        customer,
        invoiceList,
        PaymentMode,
        getBankTransfer,
        transactionList,
        bankingHistory,
        paymentRecieved,
        PaymentRefunds,
        setId,
        id,
        fetchAllData,
        userList,
        vendors,
      }}
    >
      {children}
    </dataContext.Provider>
  );
};
