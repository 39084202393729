import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  Grid,
  Typography,
  Divider,
  TextField,
  Button,
  Box,
} from "@mui/material";
import React, { useState } from "react";
import { useDataContext } from "../../../context/Context";
import { formatNumber } from "../../../components/formatNumber";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function ExpenseTab() {
  const { bankingHistory, transactionList, invoiceList, bankTransferData } =
    useDataContext();

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const downloadPDF = () => {
    // setDownloading(true); // Set state to hide "Print" column
    const input = document.getElementById("report-content");
    if (!input) return;

    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "p",
        unit: "in",
        format: "letter",
      });
      const pdfWidth = 8.5;
      const pdfHeight = 11;
      const imgWidth = pdfWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const adjustedHeight = imgHeight > pdfHeight ? pdfHeight : imgHeight;

      // Set yOffset to 0 to reduce padding from the top
      const yOffset = 0;
      pdf.addImage(imgData, "PNG", 0, yOffset, imgWidth, adjustedHeight);
      pdf.save("Report");
    });

    // setDownloading(false); // Reset state to show "Print" column
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // Filter data by date
  const filteredDataDebit = bankingHistory.filter((entry) => {
    const entryDate = new Date(entry.createdAt);
    return (
      (!startDate || entryDate >= new Date(startDate)) &&
      (!endDate || entryDate <= new Date(endDate))
    );
  });

  const voided = invoiceList.filter((row) => row?.exp == "Void");

  const dataRow = transactionList.filter(
    (row) =>
      row?.exp !== "Void" &&
      !voided.some((voidedItem) => voidedItem._id === row.invoiceId)
  );

  const filteredDataTransaction = dataRow.filter((entry) => {
    const entryDate = new Date(entry.createdAt);
    return (
      (!startDate || entryDate >= new Date(startDate)) &&
      (!endDate || entryDate <= new Date(endDate))
    );
  });

  // Paginate data
  // const paginatedData = filteredData.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );

  const groupedData = filteredDataTransaction.reduce((acc, curr) => {
    const existingItem = acc.find((el) => el.item === curr.item);
    if (existingItem) {
      existingItem.number += 1;
      existingItem.price += parseInt(curr.price);
    } else {
      acc.push({ item: curr.item, number: 1, price: parseInt(curr.price) });
    }
    return acc;
  }, []);

  const groupedParticular = filteredDataTransaction.reduce((acc, curr) => {
    const existingItem = acc.find((el) => el.userTagged === curr.taggedUser);
    if (existingItem) {
      existingItem.number += 1;
      existingItem.total += parseInt(curr.price);
    } else {
      acc.push({
        item: curr.item,
        userTagged: curr.taggedUser,
        number: 1,
        total: parseInt(curr.price),
      });
    }
    return acc;
  }, []);

  const groupedDebit = filteredDataDebit.reduce((acc, curr) => {
    if (curr.type === "Expenses" || curr.type === "Purchases") {
      const existingItem = acc.find((el) => el.userTagged === curr.taggedUser);
      if (existingItem) {
        existingItem.number += 1;
        existingItem.total += parseInt(curr.amount);
      } else {
        acc.push({
          item: curr.item,
          userTagged: curr.taggedUser,
          number: 1,
          total: parseInt(curr.amount),
        });
      }
    }
    return acc;
  }, []);

  const totalDataAmount = groupedData.reduce(
    (sum, item) => sum + item.price,
    0
  );
  const totalParticularAmount = groupedDebit.reduce(
    (sum, item) => sum + item.total,
    0
  );

  return (
    <Box
      sx={{
        // height: "80vh",
        width: "70%",
        margin: "auto",
        p: 3,
      }}
    >
      <div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          {/* Date Range Picker */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              style={{ marginRight: 8 }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          {/* Buttons for Export PDF and CSV */}
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => downloadPDF()}
            >
              Export as PDF
            </Button>
            <Button variant="outlined" color="secondary">
              Export as CSV
            </Button>
          </Box>
        </Box>

        <Grid container spacing={3} id="report-content">
          <Grid item xs={12} md={12}>
            <Typography variant="h6" sx={{ fontWeight: 800 }}>
              Income and Expenditure
            </Typography>
            <Divider sx={{ my: 1 }} />

            <div className="tablebodyex">
              <ul className="haedingUl">
                <li>
                  <ul>
                    <li>Particular</li>
                    <li>Debit</li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>Particular</li>
                    <li>Credit</li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  {groupedDebit.map((a, b) => (
                    <ul className="trax" key={b}>
                      <li>{a.userTagged}</li>
                      <li>{formatNumber(a.total)}</li>
                    </ul>
                  ))}
                </li>
                <li>
                  {groupedParticular.map((a, b) => (
                    <ul className="trax" key={b}>
                      <li>{a.userTagged}</li>
                      <li>{formatNumber(a.total)}</li>
                    </ul>
                  ))}
                </li>
              </ul>

              <ul>
                <li>
                  <ul>
                    <li>Total :</li>
                    <li>
                      <b>{formatNumber(totalParticularAmount)}</b>
                    </li>
                  </ul>
                </li>
                <li>
                  <ul>
                    <li>Total :</li>
                    <li>
                      <b>{formatNumber(totalDataAmount)}</b>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
              Account Balances
            </Typography>
            <Divider sx={{ my: 1 }} />

            <div style={{ width: "80%", margin: "auto" }}>
              {bankTransferData.map((a, index) => (
                <div
                  key={index}
                  style={
                    index % 2 === 1
                      ? { backgroundColor: "#f8edeb", color: "#da627d" }
                      : { backgroundColor: "white" }
                  }
                >
                  <ul
                    style={{
                      display: "flex",
                      listStyle: "none",
                      padding: 0,
                      margin: 0,
                      alignItems: "center",
                      justifyItems: "center",
                    }}
                  >
                    <li
                      style={{ flex: 1, margin: 0, padding: 0 }}
                      align="center"
                    >
                      {a.accountName}
                    </li>
                    <li
                      style={{ flex: 1, margin: 0, padding: 5 }}
                      align="center"
                    >
                      {formatNumber(a.balance)}
                    </li>
                  </ul>
                </div>
              ))}
            </div>

            <div>
              <br /> <br />
              <Typography variant="h6" align="center" sx={{ fontWeight: 800 }}>
                Transaction Summary
              </Typography>
              <Divider sx={{ my: 1 }} />
              {groupedData.map((a, b) => (
                <div className="summary" key={b}>
                  <ul>
                    <li> {a.item} :</li>
                    <li>
                      {formatNumber(a.price)} AED ({a.number} Count)
                    </li>
                  </ul>
                </div>
              ))}
              <Divider sx={{ my: 1 }} />
              <div className="summary">
                <ul>
                  <li> Total Sales :</li>
                  <li>{formatNumber(totalDataAmount)} AED</li>
                </ul>
                <ul>
                  <li> Total Purchases & Expenses :</li>
                  <li>{formatNumber(totalParticularAmount)} AED</li>
                </ul>
                <ul>
                  <li>Total Gross Profit :</li>
                  <li>
                    <b>
                      {formatNumber(totalDataAmount - totalParticularAmount)}{" "}
                      AED
                    </b>
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
