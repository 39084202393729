import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme } from "@mui/material/styles";
import { AppProvider } from "@toolpad/core/AppProvider";
import { DashboardLayout } from "@toolpad/core/DashboardLayout";
import { Route, Routes, useNavigate } from "react-router-dom";

// Components
import UserManagement from "./users/users";
import Header from "./Header";
import ItemManagement from "./items/Itemslist";
import AddClientForm from "./customers/Addcustomer";
import CustomerManagement from "./customers/Viewcustomer";
import Add from "./vendors/Add";
import View from "./vendors/View";
import InvoiceList from "./sales/invoicelist";
import InvoiceVoidedList from "./sales/voided";
import TransactionList from "./sales/transactionList";
import AddItems from "./items/AddItems";
import ItemList from "./items/Itemslist";
import AddInvoice from "./sales/Sections/AddInvoice";
import Addpurchases from "./purchases/Sections/AddPuchases";
import BankTransfers from "./bankings/bankTransfers";
import BankList from "./bankings/banks";
import PurchaseList from "./purchases/puchaselist";
import ExpenseList from "./expense/expenselist";
import AddExpenseForm from "./expense/section/Addexpense";
import SaleReport from "./report/salereport";
import Report from "./report/Report";
// Updated NAVIGATION configuration
import AddNewBankForm from "./bankings/Section/AddBank";
import ViewInvoice from "./sales/Sections/ViewInvoice";
import Login from "./Auth/Login";
import PaymentRecieved from "./sales/paymentrecived";
import ViewPayment from "./sales/Sections/ViewPaymentRecieved";
import ViewFlightrix from "./sales/Sections/ViewFlightrix";
import PaymentRefunds from "./sales/refunds";
import ViewRefund from "./sales/Sections/ViewRefund";
import ViewPurcahases from "./purchases/Sections/ViewPurchase";
import RecurringBillPage from "./RecurringBill/bill";
import ProfitAndLossPage from "./report/ProfitAndLoss";
import Dashboard from "./Dashbaord/Dashbaord";

function DemoPageContent({ pathname }) {
  return (
    <Box
      sx={{
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography>Dashboard content for {pathname}</Typography>
    </Box>
  );
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardLayoutBasic(props) {
  return (
    <Box
      style={{
        width: "83vw",
        marginLeft: "15vw",
        background: "transparent",
      }}
    >
      <Routes>
        <Route path="/" element={<InvoiceList />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/audit/auditList" element={<ItemManagement />} />
        <Route path="/logs/viewLogs" element={<ItemManagement />} />
        <Route path="/banks/bankList" element={<BankList />} />
        <Route path="/customers/list" element={<CustomerManagement />} />
        <Route path="/customers/add" element={<AddClientForm />} />
        <Route path="/items/list" element={<ItemList />} />
        <Route path="/items/add" element={<AddItems />} />
        <Route path="/vendors/add" element={<Add />} />
        <Route path="/vendors/list" element={<View />} />
        <Route path="/sales/invoice/add" element={<AddInvoice />} />
        <Route path="/sales/credit-notes" element={<PaymentRefunds />} />
        <Route path="/sales/view/:Id" element={<ViewInvoice />} />
        <Route path="/purchase/view/:Id" element={<ViewPurcahases />} />
        <Route path="/payment/view/:Id" element={<ViewPayment />} />
        <Route path="/refund/view/:Id" element={<ViewRefund />} />
        <Route path="/flightrix/view/:Id" element={<ViewFlightrix />} />
        <Route path="/purchases/add" element={<Addpurchases />} />
        <Route path="/purchases/list" element={<PurchaseList />} />
        <Route path="/reports/ProfitAndLoss" element={<ProfitAndLossPage />} />
        <Route
          path="/purchases/RecurringBills"
          element={<RecurringBillPage />}
        />
        <Route path="/banks/addBank" element={<AddNewBankForm />} />
        <Route path="/banks/bankStatements" element={<BankTransfers />} />
        <Route path="/sales/invoice/list" element={<InvoiceList />} />
        <Route path="/sales/voided/list" element={<InvoiceVoidedList />} />
        <Route path="/sales/payment/received" element={<PaymentRecieved />} />
        <Route path="/expenses/list" element={<ExpenseList />} />
        <Route path="/expenses/add" element={<AddExpenseForm />} />
        <Route path="/sales/transaction/list" element={<TransactionList />} />
        <Route path="/purchases" element={<ItemManagement />} />
        <Route path="/reports" element={<ItemManagement />} />
        <Route path="/orders" element={<ItemManagement />} />
        <Route path="/payments" element={<ItemManagement />} />
        <Route path="/analytics" element={<ItemManagement />} />
        <Route path="/support" element={<ItemManagement />} />
        <Route path="/support" element={<ItemManagement />} />
        <Route path="/security" element={<ItemManagement />} />
        <Route path="/reports/sales" element={<Report />} />
        <Route path="/reports/advanced" element={<SaleReport />} />
      </Routes>
    </Box>
  );
}
DashboardLayoutBasic.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayoutBasic;
