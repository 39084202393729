import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

import { useNavigate } from "react-router-dom";
// Clear all cookies
const clearAllCookies = () => {
  const allCookies = Cookies.get(); // Get all cookies
  Object.keys(allCookies).forEach((cookie) => {
    Cookies.remove(cookie); // Remove each cookie
  });
};
// Call the function to clear all cookies
// Initial Axios setup
axios.defaults.withCredentials = true;
const ApiPath = axios.create({
  // baseURL: "http://localhost:8800/api", // or your production URL
  baseURL: "https://newcrm-d5pb.onrender.com/api", // or your production URL
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
// Add response interceptor to handle 403 responses
ApiPath.interceptors.response.use(
  (response) => response, // Pass through any successful responses
  (error) => {
    // if (error.response && error.response.status === 403) {
    if (error.response.status === 403) {
      Cookies.remove("_id");
      let navigate = useNavigate();
      navigate("/login");
      window.location.reload();
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default ApiPath;
