import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Container,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import ApiPath from "../../utils/api";
import { countryData } from "../../lib/data";
import { useDataContext } from "../../context/Context";
import { message } from "antd";
import { useNavigate } from "react-router-dom";

const AddClientForm = ({ data }) => {
  const { userList } = useDataContext();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    salutation: "",
    openingBalance: "0",
    firstName: "",
    lastName: "",
    tags: "",
    mobileCode: "",
    mobileNumber: "",
    telephoneCode: "",
    telephoneNumber: "",
    address: "",
    refereeName: "",
    refereeMobile: "",
    refereeEmail: "",
    city: "",
    country: "",
    nationality: "",
    paymentTerms: "",
    salePerson: "",
    notes: "",
    source: "",
    companyName: "",
  });

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data,
      }));
    }
  }, [data]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Preview Dialog toggle
  const togglePreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.email || !formData.firstName || !formData.mobileNumber) {
      alert("Please fill in the required fields.");
      return;
    }
    try {
      // Send data to the backend API
      const response = await ApiPath.post("/customer", formData);
      message.success("Customer submitted successfully!");
      navigate(`/customers/list`);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting customer:", error);
      message.error("Failed to submit customer.");
    }
  };

  const handleUpdate = async () => {
    if (!formData.email || !formData.firstName || !formData.mobileNumber) {
      message.error("Please fill in the required fields.");
      return;
    }
    try {
      // Send data to the backend API
      const response = await ApiPath.put(`/customer/${formData._id}`, formData);
      window.location.reload();

      message.success("Customer Updated successfully!");
    } catch (error) {
      console.error("Error submitting customer:", error);
      message.error("Failed to submit customer.");
    }
  };

  return (
    <Box sx={{ p: 3, background: "white" }}>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={7}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                Client Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button variant="outlined" component="label">
                    Choose File
                    <input hidden accept="image/*" type="file" />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Salutation</InputLabel>
                    <Select
                      name="salutation"
                      value={formData.salutation}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Ms">Ms</MenuItem>
                      <MenuItem value="Mrs">Mrs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Opening Balance"
                    name="openingBalance"
                    value={formData.openingBalance}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Tags"
                    name="tags"
                    value={formData.tags}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel>Mobile</InputLabel>
                    <Select
                      name="mobileCode"
                      value={formData.mobileCode}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="+256">+256</MenuItem>
                      <MenuItem value="+971">+971</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Enter Mobile"
                    name="mobileNumber"
                    value={formData.mobileNumber}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel>Telephone</InputLabel>
                    <Select
                      name="telephoneCode"
                      value={formData.telephoneCode}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="+256">+256</MenuItem>
                      <MenuItem value="+971">+971</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="Enter Telephone"
                    name="telephoneNumber"
                    value={formData.telephoneNumber}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
              {/* Other Information */}
              <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
                Other Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Referee Name"
                    name="refereeName"
                    value={formData.refereeName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Referee Mobile"
                    name="refereeMobile"
                    value={formData.refereeMobile}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Country</InputLabel>
                    <Select
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                    >
                      {countryData.map((a, b) => (
                        <MenuItem value={a.name}>{a.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Referee Email"
                    name="refereeEmail"
                    value={formData.refereeEmail}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Nationality</InputLabel>
                    <Select
                      name="nationality"
                      value={formData.nationality}
                      onChange={handleInputChange}
                    >
                      {countryData.map((a, b) => (
                        <MenuItem value={a.name}>{a.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Payment Terms</InputLabel>
                    <Select
                      name="paymentTerms"
                      value={formData.paymentTerms}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="1 Month">1 Month</MenuItem>
                      <MenuItem value="2 Month">2 Month</MenuItem>
                      <MenuItem value="2 Week">2 Week</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Sale Person</InputLabel>
                    <Select
                      name="salePerson"
                      value={formData.salePerson}
                      onChange={handleInputChange}
                    >
                      {userList.map((a, b) => (
                        <MenuItem value={a.FirstName + " " + a.LastName}>
                          {a.FirstName + " " + a.LastName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    value={formData.notes}
                    onChange={handleInputChange}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Source"
                    name="source"
                    value={formData.source}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Card variant="outlined">
                <CardHeader title="Preview" />
                <Divider />
                <CardContent>
                  <Typography variant="h6">Client Information</Typography>
                  <Typography>Email: {formData.email}</Typography>
                  <Typography>First Name: {formData.firstName}</Typography>
                  <Typography>Last Name: {formData.lastName}</Typography>
                  <Typography>
                    Mobile: {formData.mobileCode} {formData.mobileNumber}
                  </Typography>
                  <Typography>
                    Telephone: {formData.telephoneCode}{" "}
                    {formData.telephoneNumber}
                  </Typography>
                  <Typography>Address: {formData.address}</Typography>
                </CardContent>
                <Divider />
                <Dialog open={isPreviewOpen} onClose={togglePreview}>
                  <DialogContent>
                    <Typography variant="h5">Preview Details</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={togglePreview}>Close</Button>
                  </DialogActions>
                </Dialog>
                <DialogActions>
                  <Button variant="outlined" onClick={togglePreview}>
                    Show Preview
                  </Button>

                  {!data ? (
                    <Button type="submit" variant="contained" color="primary">
                      Submit
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleUpdate()}
                      variant="contained"
                      color="primary"
                      style={{ background: "brown" }}
                    >
                      Update
                    </Button>
                  )}
                </DialogActions>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

export default AddClientForm;
