import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  list-style: none;
  height: 20px;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    background: lightgrey;
    border-left: 1px solid #632ce4;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
  margin-top: -3px;
`;

const DropdownLink = styled(Link)`
  background: #f4f4f5;
  height: 40px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #282829;
  font-size: 14px;

  &:hover {
    background: #f9f9fa;
    cursor: pointer;
  }
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
              ? item.iconClosed
              : null}
        </div>
      </SidebarLink>
      {subnav &&
        item?.subNav?.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
      
    </>
  );
};

export default SubMenu;
