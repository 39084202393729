import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import ApiPath from "../../utils/api";
import { Accounts } from "../../lib/data";
import { message, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDataContext } from "../../context/Context";
function AddNewItemForm({ data }) {
  const Navigate = useNavigate();
  const { fetchAllData } = useDataContext();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    ItemName: "",
    ItemCategory: "",
    Tags: "",
    SellingPrice: "",
    Account: "",
    ItemDescription: "",
    PurchasePrice: "",
    Account2: "",
    ItemDescription2: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    if (data) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data,
      }));
    }
  }, [data]);

  const validateForm = () => {
    let newErrors = {};
    if (!formData.ItemName) newErrors.ItemName = "Item name is required";
    if (!formData.ItemCategory) newErrors.ItemCategory = "Category is required";
    if (!formData.SellingPrice)
      newErrors.SellingPrice = "Selling price is required";
    if (!formData.Account) newErrors.Account = "Account is required";
    if (!formData.PurchasePrice)
      newErrors.PurchasePrice = "Purchase price is required";
    if (!formData.Account2) newErrors.Account2 = "Account is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    if (Number(formData.SellingPrice) < Number(formData.PurchasePrice)) {
      message.error("Purchase price less than selling price");
      return;
    }
    try {
      // Send data to the backend API
      const response = await ApiPath.post("/item", formData);
      fetchAllData();
      message.success("Item submitted successfully!");
      Navigate("/items/list");
      window.location.reload();
    } catch (error) {
      console.error("Error submitting item:", error);
      message.error("Failed to submit item.");
    }
    setLoading(false);
  };
  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }
    if (formData.SellingPrice < formData.PurchasePrice) {
      message.error("");
    }
    try {
      const response = await ApiPath.put(`/item/${formData._id}`, formData);
      message.success("Item saved:");
      Navigate("/items/list");
      window.location.reload();
    } catch (error) {
      message.error("Item saved:");
    }
  };

  return (
    <Box
      className="page"
      component="form"
      noValidate
      onSubmit={handleSubmit}
      style={{ background: "white", padding: 20 }}
    >
      <Container>
        <Typography
          variant="h5"
          align="center"
          gutterBottom
          className="smallHeading"
        >
          ADD NEW ITEM
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={7}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Item Information
              </Typography>
              <TextField
                label="Item Name"
                name="ItemName"
                fullWidth
                margin="normal"
                placeholder="Enter your item Name"
                variant="outlined"
                required
                error={!!errors.ItemName}
                helperText={errors.ItemName}
                value={formData.ItemName}
                onChange={handleChange}
              />
              <TextField
                select
                label="Item Category"
                name="ItemCategory"
                fullWidth
                margin="normal"
                variant="outlined"
                required
                error={!!errors.ItemCategory}
                helperText={
                  errors.ItemCategory || "Please select Item Category"
                }
                value={formData.ItemCategory}
                onChange={handleChange}
              >
                <MenuItem value="">Please select Item Category</MenuItem>
                <MenuItem value="Air Ticket">Air Ticket</MenuItem>
                <MenuItem value="Visa">Visa</MenuItem>
                <MenuItem value="Tours">Tours</MenuItem>
                <MenuItem value="Tour Package">Tour Package</MenuItem>
                <MenuItem value="Account Opening">Account Opening</MenuItem>
                <MenuItem value="Web Services">Web Services</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
                <MenuItem value="Residence">Residence</MenuItem>
                <MenuItem value="Hotel Booking">Hotel Booking</MenuItem>
                <MenuItem value="Transport">Transport</MenuItem>
                <MenuItem value="Fines">Fines</MenuItem>
              </TextField>
              <TextField
                label="Tags"
                name="Tags"
                fullWidth
                margin="normal"
                placeholder="Tags..."
                variant="outlined"
                value={formData.Tags}
                onChange={handleChange}
              />
            </Box>
            <Divider sx={{ marginY: 3 }} />
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Box className="col">
                  <Typography variant="h6" gutterBottom>
                    Sales Information
                  </Typography>
                  <TextField
                    label="Selling Price"
                    name="SellingPrice"
                    type="number"
                    fullWidth
                    margin="normal"
                    placeholder="Enter your Selling Price"
                    variant="outlined"
                    required
                    error={!!errors.SellingPrice}
                    helperText={errors.SellingPrice}
                    value={formData.SellingPrice}
                    onChange={handleChange}
                  />
                  <TextField
                    select
                    label="Account"
                    name="Account"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    required
                    error={!!errors.Account}
                    helperText={errors.Account || "Please select Account"}
                    value={formData.Account}
                    onChange={handleChange}
                  >
                    {Accounts.map((a, b) => (
                      <MenuItem key={b} value={a.AccountName}>
                        {a.AccountName}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    label="Item Description"
                    name="ItemDescription"
                    fullWidth
                    margin="normal"
                    placeholder="Description..."
                    variant="outlined"
                    value={formData.ItemDescription}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className="col">
                  <Typography variant="h6" gutterBottom>
                    Purchase Information
                  </Typography>

                  <TextField
                    label="Buying Price"
                    name="PurchasePrice"
                    type="number"
                    fullWidth
                    margin="normal"
                    placeholder="Enter your Buying Price"
                    variant="outlined"
                    required
                    error={!!errors.PurchasePrice}
                    helperText={errors.PurchasePrice}
                    value={formData.PurchasePrice}
                    onChange={handleChange}
                  />

                  <TextField
                    select
                    label="Account"
                    name="Account2"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    required
                    error={!!errors.Account2}
                    helperText={errors.Account2 || "Please select Account"}
                    value={formData.Account2}
                    onChange={handleChange}
                  >
                    {Accounts.map((a, b) => (
                      <MenuItem key={b} value={a.AccountName}>
                        {a.AccountName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    label="Item Description"
                    name="ItemDescription2"
                    fullWidth
                    margin="normal"
                    placeholder="Description..."
                    variant="outlined"
                    value={formData.ItemDescription2}
                    onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Preview Section */}
          <Grid item xs={12} md={5}>
            <Card>
              <CardHeader title="Preview" />
              <CardContent>
                <Typography variant="h6">Item Information</Typography>
                <Typography>
                  <strong>Name:</strong> {formData.ItemName || "N/A"}
                </Typography>
                <Typography>
                  <strong>Category:</strong> {formData.ItemCategory || "N/A"}
                </Typography>
                <Typography>
                  <strong>Tags:</strong> {formData.Tags || "N/A"}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6">Sales Information</Typography>
                <Typography>
                  <strong>Selling Price:</strong>{" "}
                  {formData.SellingPrice || "N/A"}
                </Typography>
                <Typography>
                  <strong>Account:</strong> {formData.Account || "N/A"}
                </Typography>
                <Typography>
                  <strong>Description:</strong>{" "}
                  {formData.ItemDescription || "N/A"}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6">Purchase Information</Typography>
                <Typography>
                  <strong>Buying Price:</strong>{" "}
                  {formData.PurchasePrice || "N/A"}
                </Typography>
                <Typography>
                  <strong>Account:</strong> {formData.Account2 || "N/A"}
                </Typography>
                <Typography>
                  <strong>Description:</strong>{" "}
                  {formData.ItemDescription2 || "N/A"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box
          align="center"
          sx={{ width: "50%", margin: "auto", marginTop: "20px" }}
        >
          {data ? (
            <Button variant="contained" onClick={() => handleUpdate()}>
              Update
            </Button>
          ) : (
            <Button
              type="submit"
              htmlType="submit"
              loading={loading}
              style={{ background: "blue", color: "white", width: "150px" }}
            >
              Save
            </Button>
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default AddNewItemForm;
