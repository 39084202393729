import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
// import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import AddAmenity from "./Add/AddAmedity";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridDeleteIcon } from "@mui/x-data-grid";
import ApiPath from "../utils/api";
import { Modal } from "antd";

const RowOptions = ({ data, link, Page }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);
  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setModalOpen(true);
  };

  // const handleVoid = async (id) => {
  //   console.log("handleVoid", data?.row?._id);
  //   try {
  //     const response = await ApiPath.put(
  //       `/invoice/void/${data?.row?._id}`,
  //       data?.row
  //     );
  //     const result = await response.data();
  //     console.log("Void successful:", result);
  //     alert("Void successful");
  //   } catch (error) {
  //     console.error("Error voiding the item:", error);
  //     alert("Error voiding the item");
  //   }
  // };

  const handleVoid = async (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "Do you really want to void this item?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        console.log("handleVoid", data?.row?._id);
        try {
          const response = await ApiPath.put(
            `/invoice/void/${data?.row?._id}`,
            data?.row
          );
          const result = await response.data;
          console.log("Void successful:", result);
          alert("Void successful");
        } catch (error) {
          console.error("Error voiding the item:", error);
          alert("Error voiding the item");
        }
      },
      onCancel: () => {
        console.log("Void action cancelled");
      },
    });
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "4rem" } }}
      >
        <Link to={`/sales/view/${link}`}>
          <MenuItem
            component={Link}
            to={`/sales/view/${link}`}
            sx={{ "& svg": { marginRight: 2 } }}
            onClick={handleRowOptionsClose}
          >
            <RemoveRedEyeIcon color="primary" fontSize="small" />
            View
          </MenuItem>
        </Link>
        <MenuItem onClick={handleEdit} sx={{ "& svg": { marginRight: 2 } }}>
          <EditIcon color="primary" fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem
          component={Link}
          to=""
          sx={{ "& svg": { marginRight: 2 } }}
          onClick={handleRowOptionsClose}
        >
          <GridDeleteIcon sx={{ fontSize: "20px" }} />
          Delete
        </MenuItem>
        <MenuItem
          to=""
          sx={{ "& svg": { marginRight: 2 }, color: "red" }}
          onClick={() => handleVoid()}
        >
          <GridDeleteIcon sx={{ fontSize: "20px" }} />
          Void
        </MenuItem>
      </Menu>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Page data={data.row} />
        </Box>
      </Modal>
    </>
  );
};

export default RowOptions;
