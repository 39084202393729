import * as React from "react";
import "./Bank.css";
import { useContext, useEffect, useState } from "react";
// import swal from "sweetalert";
// import { UserContext } from "../../../contexts/user.context";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { brown } from "@mui/material/colors";
import { useDataContext } from "../../context/Context";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from "@mui/material";
import { formatNumber } from "../../components/formatNumber";
import * as XLSX from "xlsx";
import { PublishIcon } from "@mui/icons-material";

export default function ViewBank(props) {
  const { bankTransferData, bankingHistory, getBankTransfer } =
    useDataContext();
  const [manage, setManage] = useState(1);
  const [searchInvoice, setSearchInvoice] = useState("");
  const [banks, setBank] = useState([]);
  const [transferArrayvv, setTransfers] = useState([]);
  const [searchInvoiceLoad, setSearchInvoiceLoad] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [customer, setCustomers] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  // State for pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set default rows per page

  // Handle change for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Calculate the rows to display based on the pagination state
  const displayedRows = bankingHistory
    .sort((a, b) => {
      const dateA = new Date(a.createdAt); // Convert the ISO date string to a Date object
      const dateB = new Date(b.createdAt);
      return dateB - dateA; // Sort from earliest to latest
    })
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  // console.log("bankTransferData", bankTransferData);

  const [acB, setAcB] = useState("");

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "white",
    color: "black",
    height: "70vh",
    borderRadius: "10px",
    //   boxShadow: 24,
  };
  const style2 = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1500,
    bgcolor: "white",
    border: "2px solid #000",
    color: "black",
    height: "auto",
    //   boxShadow: 24,
    p: 0,
  };

  // const [banks, setBanks] = useState([]);

  useEffect(() => {
    // console.log("user", user.id);
    // loadInvoices();
  }, []);

  const [items, setItems] = useState([
    {
      Amount: "0",
      Customer_Name: " ",
      Date: " ",
      Description: "",
      FromAccount: 0,
      Id: 1,
      IndexBankTransfer: manage,
      Poster: localStorage.getItem("Mail"),
      Reciept: "",
      Reference: "",
      ToAccount: "",
    },
  ]);

  const handleFormChange = (event) => {
    console.log("data ex", event.target.value);
    let data = [...items];
    items[0][event.target.name] = event.target.value;
    setItems(data);
  };

  async function AddBank() {}
  const getBalance = (bk) => {
    let data = 0;
    for (let a = 0; a < banks.length; a++) {
      if (bk == banks[a].BankName) {
        data = parseInt(banks[a].BankBalance);
      }
    }
    return data;
  };

  // const getBaseCurrent = (a, b) => {
  //   let num = 0;
  //   const index = filteredRows.length - a; // Get the index based on 'a'
  //   // Check if the calculated index is within boundsr
  //   // if (index >= 0 && index < filteredRows.length) {
  //   num = Number(filteredRows[index]?.amount);
  //   // }

  //   return num;
  // };
  const filteredRows = displayedRows.filter((row) =>
    row.toAccount?.toLowerCase().includes(acB)
  );
  const calculateBalance = (index, currentBalance, filteredRows) => {
    let minBal = displayedRows[index].amount;
    let beforeBal = 0;

    console.log("filteredRows", displayedRows);

    for (let a = 0; a <= index; a++) {
      let transaction = displayedRows[a];
      if (transaction.type === "" || transaction.type === "Purchases") {
        beforeBal = Number(beforeBal) - transaction.amount; // Subtract debit amount
      } else if (transaction.type === "Sales") {
        beforeBal = Number(beforeBal) + Number(transaction.amount); // Add credit amount
      }
    }
    // return minBal + beforeBal; // Return the updated balance
    return beforeBal; // Return the updated balance
  };

  // const calculateBalance = (currentBalance, transaction, index) => {
  //   // console.log("darax", currentBalance);Expenses
  //   // console.log("tr", transaction);
  //   let newBalance = currentBalance;
  //   for (let a = 0; a <= filteredRows.length; a++) {
  //     newBalance += filteredRows[a].amount;
  //   }
  //   if (transaction.type == "Expenses" || transaction.type == "Purchases") {
  //     newBalance -= Number(transaction.amount);
  //   }
  //   if (transaction.type == "Sales") {
  //     newBalance = Number(newBalance) + Number(transaction.amount);
  //   }
  //   return newBalance;
  // };

  return (
    <Box
      className="page pagexx"
      component="form"
      noValidate
      style={{ background: "white", padding: 20, height: "90vh" }}
    >
      <div className="bankSection">
        <div></div>
        <div>
          <div className="manageaudit">
            <ul>
              <li style={{ background: " " }}>
                <div
                  className="searchBox"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TextField
                    style={{ marginRight: "8px" }}
                    placeholder="Search"
                    value={searchInvoice}
                    onChange={(e) => setSearchInvoice(e.target.value)}
                    variant="outlined"
                    InputProps={{
                      style: { height: "46px" }, // Adjust height to match Select
                    }}
                  />

                  <FormControl sx={{ m: 1, width: "30%" }}>
                    {/* <InputLabel>Salutation</InputLabel> */}
                    <Select
                      style={{ height: 46 }}
                      name="salutation"
                      value="All Banks"
                      // value={formData.salutation}
                      // onChange={handleInputChange}
                    >
                      <MenuItem value="All Banks">All Banks</MenuItem>
                      <MenuItem value="Ms">Ms</MenuItem>
                      <MenuItem value="Mrs">Mrs</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    style={{ marginRight: "8px" }}
                    type="date"
                    variant="outlined"
                    InputProps={{
                      style: { height: "46px" }, // Adjust height to match Select
                    }}
                  />
                  <TextField
                    style={{
                      marginRight: "8px",
                      borderTopRightRadius: "10px",
                      borderBottomRightRadius: "10px",
                    }}
                    type="date"
                    variant="outlined"
                    InputProps={{
                      style: { height: "46px" }, // Adjust height to match Select
                    }}
                  />
                </div>
              </li>

              <li>
                <div
                  className="searchBox"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    className="addbutton"
                    align="center"
                    onClick={() => setOpen(true)}
                    style={{ width: "150px", marginRight: "8px" }}
                    variant="contained"
                  >
                    ADD ACCOUNT
                  </Button>
                  <Button
                    className="addbutton"
                    variant="contained"
                    onClick={() => setOpen(true)}
                    style={{ color: "black", background: "#ffe5d9" }}
                  >
                    MAKE TRANSFER
                  </Button>
                </div>
              </li>
              <li align="right" style={{ marginTop: "16px" }}>
                <div
                  className="nawdashLabel"
                  style={{
                    paddingTop: "2px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "10px",
                      border: "none",
                      backgroundColor: " #e4b7b7",
                      color: "#a01c1c",
                    }}
                  >
                    PDF
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#d1ddf7",
                      color: "#093240",
                      border: "none",
                    }}
                  >
                    PRINT
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "10px",
                      backgroundColor: "#c6f6d1",
                      color: " #0a6323",
                      border: "none",
                    }}
                  >
                    CSV
                  </Button>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="">
          <div className=" ">
            <div className="_bankList">
              <div align="right">
                <div>
                  <Modal open={open2} onClose={handleClose2}>
                    <Box sx={style2}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <div className="transferTab">
                          <u>Transfer History</u>
                          <div className="transArray">
                            <ul>
                              <li>Date</li>
                              <li>Amount.</li>
                              <li>Amount To.</li>
                              <li>Paid Via.</li>
                              <li>Transfer Type.</li>
                            </ul>
                            <hr />
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                </div>
                <div></div>
              </div>
              <div className="banksTabs">
                <ul style={{ marginTop: 20, paddingLeft: 0 }}>
                  <li
                    style={{
                      marginTop: 0,
                      marginLeft: 0,
                      paddingLeft: 0,
                      width: "10%",
                      borderRight: "1px solid lightgrey",
                      height: "80vh",
                    }}
                  >
                    <div
                      className="btab"
                      style={
                        acB == ""
                          ? { backgroundColor: "#f8edeb", color: "#da627d" }
                          : { backgroundColor: "white" }
                      }
                      onClick={() => setAcB("")}
                    >
                      All Accounts
                    </div>
                    {bankTransferData.map((a, index) => (
                      <div
                        className="btab"
                        style={
                          acB == a._id
                            ? { backgroundColor: "#f8edeb", color: "#da627d" }
                            : { backgroundColor: "white" }
                        }
                        onClick={() => setAcB(a._id)}
                      >
                        {a.accountName}
                        {/* cccc */}
                      </div>
                    ))}
                  </li>
                  <li style={{ width: "90%" }}>
                    <Box sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer>
                        <Table>
                          <TableRow style={{ background: "#f5f5f5" }}>
                            <TableCell
                              style={{ fontWeight: "bold", width: "20%" }}
                            >
                              Date
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Details
                            </TableCell>
                            {acB == "" && (
                              <TableCell style={{ fontWeight: "bold" }}>
                                Account Name
                              </TableCell>
                            )}
                            <TableCell style={{ fontWeight: "bold" }}>
                              Debit
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Credit
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Balance
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Client / Vendor
                            </TableCell>
                          </TableRow>
                          <TableBody>
                            {filteredRows
                              .reduce((acc, transaction, index) => {
                                // const prevBalance =
                                //   index === 0 ? 0 : acc[index - 1].amount;
                                const newBalance = calculateBalance(
                                  index,
                                  transaction
                                );
                                acc.push({
                                  ...transaction,
                                  balance: newBalance,
                                });
                                return acc;
                              }, [])
                              .map((item, index) => (
                                <TableRow
                                  key={item._id} // Use unique id as key
                                  style={{
                                    background:
                                      index % 2 === 0 ? "#fff4f3" : "white",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableCell>
                                    {new Date(item.createdAt).toLocaleString()}
                                  </TableCell>
                                  <TableCell>
                                    {item.type}{" "}
                                    {item.type == "Sales" ? "INV#" : "BILL#"}{" "}
                                    <span>
                                      <Link to={`/sales/view/${item.ref}`}>
                                        {item.detailId}
                                      </Link>
                                    </span>
                                    {/* to {item.toAccount} */}
                                  </TableCell>

                                  {acB == "" && (
                                    <TableCell>{item.accountName}</TableCell>
                                  )}

                                  <TableCell>
                                    {item.type !== "Sales"
                                      ? "-"
                                      : formatNumber(item.amount)}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {item.type == "Sales"
                                      ? "-"
                                      : formatNumber(item.amount)}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {acB != ""
                                      ? formatNumber(item.statementBalance)
                                      : isNaN(item.balance)
                                        ? "NaN"
                                        : formatNumber(item.balance)}
                                    {/* {getBaseCurrent(index, item.amount)} */}
                                  </TableCell>
                                  <TableCell
                                    sx={{ textTransform: "capitalize" }}
                                  >
                                    {item.taggedUser}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]} // Options for rows per page
                        component="div"
                        count={bankingHistory.length} // Total number of rows
                        rowsPerPage={rowsPerPage} // Current rows per page
                        page={page} // Current page
                        onPageChange={handleChangePage} // Function to change page
                        onRowsPerPageChange={handleChangeRowsPerPage} // Function to change rows per page
                      />
                    </Box>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
