import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
// import AddAmenity from "./Add/AddAmedity";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GridDeleteIcon } from "@mui/x-data-grid";
import AddNewBankForm from "../sections/bankings/Section/AddBank";

const RowOptionBank = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setModalOpen(true);
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "4rem" } }}
        sx={{ height: "40vh" }}
      >
        <MenuItem
          component={Link}
          to="/apps/user/view/overview"
          sx={{ "& svg": { marginRight: 2 } }}
          onClick={handleRowOptionsClose}
        >
          <RemoveRedEyeIcon color="primary" fontSize="small" />
          View
        </MenuItem>
        <MenuItem onClick={handleEdit} sx={{ "& svg": { marginRight: 2 } }}>
          <EditIcon color="primary" fontSize="small" />
          Edit
        </MenuItem>

        <MenuItem
          component={Link}
          to="/apps/user/view/overview"
          sx={{ "& svg": { marginRight: 2 } }}
          onClick={handleRowOptionsClose}
        >
          <GridDeleteIcon sx={{ fontSize: "20px" }} />
          Delete
        </MenuItem>
        <MenuItem
          component={Link}
          to="/apps/user/view/overview"
          sx={{ "& svg": { marginRight: 2 } }}
          onClick={handleRowOptionsClose}
        >
          <RemoveRedEyeIcon color="primary" fontSize="small" />
          Statement
        </MenuItem>
        {[
          "MONEY IN",
          "Expense",
          "Vendor Advance",
          "Vendor Payment",
          "Transfer To Another Account",
          "Card Payment",
          "Owner Drawings",
          "Credit Note Refund",
          "Payment Refund",
          "MONEY OUT",
          "Customer Advance",
          "Customer Payment",
          "Transfer From Another Account",
          "Interest Income",
          "Other Income",
          "Expense Refund",
          "Deposit From Other Accounts",
          "Owner's Contribution",
          "Vendor Credit Refund",
          "Vendor Payment Refund",
        ].map((item) => (
          <MenuItem
            key={item}
            onClick={handleRowOptionsClose}
            sx={{ "& svg": { marginRight: 2 } }}
          >
            <EditIcon color="primary" fontSize="small" /> {item}
          </MenuItem>
        ))}
      </Menu>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <AddNewBankForm data={data.row} />
        </Box>
      </Modal>
    </>
  );
};

export default RowOptionBank;
