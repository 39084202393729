import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { ReportData } from "../../lib/data";

export default function Report() {
  const [selected, setSelected] = useState("");
  return (
    <Box
      className="page pagexx"
      component="form"
      noValidate
      style={{ background: "white", padding: 20, height: "90vh" }}
    >
      <div style={{ padding: 0 }}>
        <div className="manageaudit" style={{}}>
          <ul style={{ padding: 0, margin: 0 }}>
            <li style={{}}>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ marginRight: "8px" }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                />

                <TextField
                  style={{
                    marginRight: "8px",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                  type="date"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                />

                <TextField
                  style={{
                    marginRight: "8px",
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                  type="date"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                />
              </div>
            </li>

            <li>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  className="addbutton"
                  align="center"
                  style={{ width: "150px", marginRight: "8px" }}
                  variant="contained"
                >
                  ADD CUSTOMER
                </Button>
              </div>
            </li>
            <li align="right" style={{ marginTop: "16px" }}>
              <div
                className="nawdashLabel"
                style={{
                  paddingTop: "2px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: " #e4b7b7",
                    color: "#a01c1c",
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#d1ddf7",
                    color: "#093240",
                    border: "none",
                  }}
                >
                  PRINT
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#c6f6d1",
                    color: " #0a6323",
                    border: "none",
                  }}
                >
                  CSV
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="bankSection">
        <div className="">
          <div className=" ">
            <div className="_bankList">
              <div align="right"></div>
              <div className="banksTabs">
                <ul style={{ marginTop: 20, paddingLeft: 0 }}>
                  <li
                    style={{
                      marginTop: 0,
                      marginLeft: 0,
                      paddingLeft: 0,
                      width: "15%",
                      borderRight: "1px solid lightgrey",
                      height: "80vh",
                    }}
                  >
                    <div
                      className=""
                      style={{
                        marginBottom: "7px",
                      }}
                    >
                      <b>Select Report </b>
                    </div>
                    {ReportData.map((a, index) => (
                      <div
                        className="btab"
                        style={
                          selected == index
                            ? { backgroundColor: "#dff1e6" }
                            : { backgroundColor: "white" }
                        }
                        onClick={() => setSelected(index)}
                      >
                        {a.name}
                      </div>
                    ))}
                  </li>
                  <li style={{ width: "90%" }}>
                    <div
                      style={{
                        marginBottom: "7px",
                      }}
                    >
                      <ul style={{ display: "flex", fontWeight: "bold" }}>
                        <li style={{ flex: 1, border: "none" }}>Date</li>
                        <li style={{ flex: 1, border: "none" }}>Details</li>
                        <li style={{ flex: 1, border: "none" }}>Debit</li>
                        <li style={{ flex: 1, border: "none" }}>Credit</li>
                        <li style={{ flex: 1, border: "none" }}>Balance</li>
                      </ul>
                    </div>
                    <div
                      className="banktabSection"
                      style={{ border: "none" }}
                    ></div>
                    <div></div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
}
