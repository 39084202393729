import {
  Box,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import React from "react";
import ExpenseTab from "./Section/ExpenseTab";

export default function SaleReport() {
  return <ExpenseTab />;
}
