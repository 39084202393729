import React, { useState } from "react";
import styled from "styled-components";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { Avatar, Button } from "antd";
import Cookies from "js-cookie";
import { Badge, Box } from "@mui/material";
import { Notifications } from "@mui/icons-material";

const Nav = styled.div`
  background: none;
  height: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #f9f9fa;
  width: 13vw;
  height: 94vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  overflow: auto;
  top: 6vh;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9fa;
  color: #000;
  padding: 16px;
  font-size: 1.2em;
  font-weight: bold;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 30px;
  margin-right: 8px;
`;

const ProfileIcon = styled.div`
  cursor: pointer;
  font-size: 1.5em;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Header style={{ position: "fixed", width: "100%", top: 0 }}>
          <Logo>
            <LogoImage
              src="https://bestoptiontravels.com/static/media/bestsv.e337dc0f8c932b77b8eef6b0d72b2442.svg"
              alt="Logo"
              height="90px"
            />
          </Logo>

          <Box sx={{ display: "flex", alignItems: "center", marginRight: 8 }}>
            <Badge badgeContent={2} color="secondary">
              <Notifications color="primary" />
            </Badge>
            <Avatar
              style={{ marginLeft: 10 }}
              alt="User Avatar"
              src="https://static.vecteezy.com/system/resources/previews/005/544/718/non_2x/profile-icon-design-free-vector.jpg"
              sx={{ marginLeft: 2 }}
            />
          </Box>
        </Header>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            {SidebarData?.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
            <Button
              style={{ marginLeft: 20 }}
              onClick={() => {
                Cookies.remove("_id");
                window.location.reload();
              }}
            >
              Log Out
            </Button>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </div>
  );
};

export default Sidebar;
