import React, { useState } from "react";
import {
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Avatar,
  FormControl,
  Select,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddClientForm from "./Addcustomer"; // Ensure this import is correct
import { useDataContext } from "../../context/Context";
import RowOptions from "./rowOption";
import { Link } from "react-router-dom";
// Function to get initials from a name
const getInitials = (name) => {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("");
};
// Predefined set of colors
const colors = [
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFC107",
  "#FF9800",
  "#FF5722",
];
// Function to get a random color from the set
const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};
const columns = (handleClick, handleClose, anchorEl, open, handleMenuClick) => [
  {
    field: "CustomerInfo",
    headerName: "Customer Info",
    flex: 1.6,
    renderCell: (params) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          // justifyContent: "center",
        }}
      >
        <Box sx={{ marginTop: 1 }}>
          <Avatar sx={{ bgcolor: getRandomColor(), fontSize: 12 }}>
            {/* {getInitials(params.row?.CustomerName)} */}
          </Avatar>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="">
            {params.row.firstName} {params.row.lastName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            BestOptions travels LLC
          </Typography>
        </Box>
      </Box>
    ),
  },
  { field: "country", headerName: "Country", flex: 1 },
  { field: "email", headerName: "Email Address", width: 300 },
  {
    field: "mobileNumber",
    headerName: "Telephone",
    flex: 1,
    renderCell: (params) => (
      <Box
        sx={{
          padding: 1,
          paddingLeft: 0,
          // background: "green",
        }}
      >
        <Typography variant="body1" fontWeight="">
          {params.row.mobileCode} {params.row.mobileNumber}
        </Typography>
      </Box>
    ),
  },

  { field: "openingBalance", headerName: "Balance", flex: 1 },
  {
    field: "actions",
    headerName: "ACTIONS",
    sortable: false,
    width: 200,
    renderCell: (row) => (
      <div>
        <IconButton color="grey" aria-label="view">
          <VisibilityIcon sx={{ fontSize: "20px" }} />
        </IconButton>{" "}
        <RowOptions id={row._id} data={row} />
      </div>
    ),
  },
];

function CustomerManagement() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // either 'view', 'edit', or 'add'
  const [searchTerm, setSearchTerm] = useState(""); // state for search input
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { customer: initialRows } = useDataContext();

  const open = Boolean(anchorEl);

  const handleMenuClick = (event, id) => {
    if (anchorEl) {
      setAnchorEl(null); // Close the currently open menu
    } else {
      setAnchorEl(event.currentTarget); // Open the new menu
    }
  };

  const handleClose = (type, row) => {
    setAnchorEl(null); // Close the menu
    if (type) {
      setSelectedRow(row);
      setModalType(type);
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedRow(null);
  };

  // Filter rows based on search input
  const filteredRows = initialRows.filter((row) =>
    String(row?.CustomerName).toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      className="page customerlist"
      style={{
        height: "90vh",
        padding: 20,
      }}
    >
      <div style={{ padding: 0 }}>
        <div className="manageaudit" style={{}}>
          <ul style={{ padding: 0, margin: 0 }}>
            <li style={{}}>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ marginRight: "8px" }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                />

                <FormControl sx={{ m: 1, width: "40%" }}>
                  {/* <InputLabel>Salutation</InputLabel> */}
                  <Select
                    style={{ height: 46 }}
                    name="salutation"
                    value="All Banks"
                    // value={formData.salutation}
                    // onChange={handleInputChange}
                  >
                    <MenuItem value="All Banks">All Banks</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </li>
            <li>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Link to="/customers/add">
                  <Button
                    className="addbutton"
                    align="center"
                    style={{ width: "150px", marginRight: "8px" }}
                    variant="contained"
                  >
                    ADD CUSTOMER
                  </Button>
                </Link>
              </div>
            </li>
            <li align="right" style={{ marginTop: "16px" }}>
              <div
                className="nawdashLabel"
                style={{
                  paddingTop: "2px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: " #e4b7b7",
                    color: "#a01c1c",
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#d1ddf7",
                    color: "#093240",
                    border: "none",
                  }}
                >
                  PRINT
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#c6f6d1",
                    color: " #0a6323",
                    border: "none",
                  }}
                >
                  CSV
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Box
        sx={{
          width: "100%",
          // marginTop: 1,
          // p: isSmallScreen ? 2 : 3,
          background: "white",
          // boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
          borderRadius: 1,
        }}
      >
        <DataGrid
          rows={filteredRows.sort((a, b) => {
            const dateA = new Date(a.createdAt); // Convert the ISO date string to a Date object
            const dateB = new Date(b.createdAt);
            return dateB - dateA; // Sort from earliest to latest
          })} // Use filtered rows here
          columns={columns(
            handleMenuClick,
            handleClose,
            anchorEl,
            open,
            handleMenuClick
          )}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row._id}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sx={{
            marginTop: 2,
            border: "none",
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "grey",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "black",
            },
          }}
        />
      </Box>

      {/* Modal for View/Edit */}

      <Dialog
        open={modalOpen}
        onClose={handleModalClose}
        fullWidth={false} // Disable fullWidth if you want a specific pixel width
        sx={{ "& .MuiDialog-paper": { width: "900px", maxWidth: "900px" } }}
      >
        <DialogTitle>
          {modalType === "view"
            ? "View Customer"
            : modalType === "edit"
              ? "Edit Customer"
              : "Add New Customer"}
        </DialogTitle>
        <DialogContent>
          {modalType === "view" && selectedRow && (
            <>
              <DialogContentText>
                Customer ID: {selectedRow.CustomerId}
              </DialogContentText>
              <DialogContentText>
                Name: {selectedRow.CustomerName}
              </DialogContentText>
              <DialogContentText>
                Email: {selectedRow.EmailAddress}
              </DialogContentText>
            </>
          )}
          {(modalType === "edit" || modalType === "add") && (
            <>
              <AddClientForm />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancel
          </Button>
          {(modalType === "edit" || modalType === "add") && (
            <Button onClick={handleModalClose} color="primary">
              {modalType === "edit" ? "Save" : "Add"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default CustomerManagement;
