import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  Box,
  IconButton,
  Select,
  MenuItem,
  Menu,
  TextField,
  Grid,
  useMediaQuery,
  useTheme,
  FormControl,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDataContext } from "../../context/Context";
import RowOptions from "./rowOption";
import { Link } from "react-router-dom";

const columns = (handleClick, handleClose, anchorEl, open) => [
  {
    field: "createdAt",
    headerName: "DATE",
    flex: 1,
    renderCell: (params) => (
      <span style={{}}>
        {new Date(params.row.createdAt).toLocaleString("en-GB")}
      </span>
    ),
  },
  {
    field: "issuedDate",
    headerName: "Invoice Date",
    flex: 1,
  },
  { field: "expenseAccount", headerName: "EXPENSE ACC.", flex: 1 },
  { field: "customerName", headerName: "CUSTOMER", flex: 1 },
  { field: "amount", headerName: "AMOUNT", flex: 1, width: 200 },
  {
    field: "actions",
    headerName: "ACTIONS",
    sortable: false,
    width: 200,
    renderCell: (row) => <RowOptions id={row._id} data={row} />,
  },
];

function ExpenseList() {
  const { expense: rows } = useDataContext();
  console.log("expense", rows);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };
  return (
    <Box
      style={{
        padding: 20,
      }}
    >
      <div style={{ padding: 0 }}>
        <div className="manageaudit" style={{}}>
          <ul style={{ padding: 0, margin: 0 }}>
            <li style={{}}>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  style={{ marginRight: "8px" }}
                  placeholder="Search"
                  variant="outlined"
                  InputProps={{
                    style: { height: "46px" }, // Adjust height to match Select
                  }}
                />
                <FormControl sx={{ m: 1, width: "40%" }}>
                  <Select
                    style={{ height: 46 }}
                    name="salutation"
                    value="All Banks"
                  >
                    <MenuItem value="All Banks">All Banks</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </li>
            <li>
              <div
                className="searchBox"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Link to="/expenses/add">
                  <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{
                      marginLeft: isSmallScreen ? 0 : 2,
                      mt: isSmallScreen ? 2 : 0,
                      height: "50px",
                      width: isSmallScreen ? "100%" : "auto",
                      backgroundColor: "#0597b1",
                    }}
                    className="buttoncolor"
                  >
                    Add Expense
                  </Button>
                </Link>
              </div>
            </li>
            <li align="right" style={{ marginTop: "16px" }}>
              <div
                className="nawdashLabel"
                style={{
                  paddingTop: "2px",
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    backgroundColor: " #e4b7b7",
                    color: "#a01c1c",
                  }}
                >
                  PDF
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#d1ddf7",
                    color: "#093240",
                    border: "none",
                  }}
                >
                  PRINT
                </Button>
                <Button
                  variant="outlined"
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#c6f6d1",
                    color: " #0a6323",
                    border: "none",
                  }}
                >
                  CSV
                </Button>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Box
        sx={{
          width: "100%",
          background: "white",
          borderRadius: 1,
        }}
      >
        <DataGrid
          rows={[...rows].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )}
          columns={columns(handleClick, handleClose, anchorEl, open)}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row?._id} // Ensure that the ID is unique
          pageSizeOptions={[10, 25, 50]} // Compatibility with latest MUI versions
          sx={{
            marginTop: 2,
            border: "none",
            "& .MuiDataGrid-cell": {
              border: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "grey",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "black",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default ExpenseList;
