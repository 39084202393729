// Import dependencies
import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  IconButton,
  Divider,
  LinearProgress,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Badge,
  CircularProgress,
} from "@mui/material";
import {
  ShoppingCart,
  TrendingUp,
  People,
  Notifications,
  Task,
  Assessment,
  MoreVert,
} from "@mui/icons-material";
import {
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
} from "recharts";

const COLORS = ["#82ca9d", "#8884d8", "#ffc658"];
const cardGradient = "linear-gradient(135deg, #f8fbff, #ffffff)";
const accentColor = "#3b82f6";

const Dashboard = () => {
  const [dateRange, setDateRange] = useState("Weekly");

  // Sample data for charts
  const lineChartData = [
    { name: "Jan", uv: 30000 },
    { name: "Feb", uv: 40000 },
    { name: "Mar", uv: 20000 },
    { name: "Apr", uv: 27000 },
    { name: "May", uv: 30000 },
    { name: "Jun", uv: 40000 },
  ];

  const pieChartData = [
    { name: "Google", value: 28 },
    { name: "Refer", value: 22 },
    { name: "Direct", value: 63 },
  ];

  const barChartData = [
    { name: "Jan", revenue: 10000 },
    { name: "Feb", revenue: 15000 },
    { name: "Mar", revenue: 8000 },
    { name: "Apr", revenue: 12000 },
    { name: "May", revenue: 17000 },
    { name: "Jun", revenue: 14000 },
  ];

  const notifications = [
    {
      id: 1,
      title: "New Order Received",
      description: "You have received a new order for Product X",
      time: "5 mins ago",
    },
    {
      id: 2,
      title: "Server Maintenance",
      description: "Scheduled maintenance is planned for tonight",
      time: "2 hours ago",
    },
  ];

  const topProducts = [
    { id: 1, name: "Product A", sales: 200, revenue: "$5000" },
    { id: 2, name: "Product B", sales: 150, revenue: "$4000" },
    { id: 3, name: "Product C", sales: 100, revenue: "$2500" },
  ];

  const tasks = [
    { id: 1, name: "Email Campaign", progress: 70 },
    { id: 2, name: "UI Design", progress: 45 },
    { id: 3, name: "Backend API Development", progress: 90 },
  ];

  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };

  return (
    <Box sx={{ padding: 4, minHeight: "100vh" }}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", color: accentColor }}
        >
          Dashboard 📊
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Badge badgeContent={2} color="secondary">
            <Notifications color="action" />
          </Badge>
          <Avatar
            alt="User Avatar"
            src="/static/images/avatar/1.jpg"
            sx={{ marginLeft: 2 }}
          />
        </Box>
      </Box>

      {/* Statistics Cards */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {[
          {
            title: "Total Orders",
            value: "$54,43",
            icon: <ShoppingCart sx={{ color: "#fff" }} />,
            change: "20% vs last month",
          },
          {
            title: "Total Earnings",
            value: "$26,41",
            icon: <TrendingUp sx={{ color: "#fff" }} />,
            change: "15% vs last month",
          },
          {
            title: "Total Sales",
            value: "$56,75",
            icon: <Assessment sx={{ color: "#fff" }} />,
            change: "20% vs last month",
          },
          {
            title: "Total Customers",
            value: "375",
            icon: <People sx={{ color: "#fff" }} />,
            change: "10% vs last month",
          },
        ].map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card
              sx={{
                background: cardGradient,
                boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                borderRadius: "12px",
                padding: 2,
                position: "relative",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -20,
                  right: -20,
                  width: 50,
                  height: 50,
                  backgroundColor: accentColor,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {stat.icon}
              </Box>
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary">
                  {stat.title}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {stat.value}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 1 }}
                >
                  {stat.change}
                </Typography>
                <LinearProgress
                  variant="determinate"
                  value={Math.random() * 100}
                  sx={{
                    mt: 2,
                    height: 8,
                    borderRadius: "5px",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: accentColor,
                    },
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Charts and Widgets */}
      <Grid container spacing={4}>
        {/* Line Chart */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              background: cardGradient,
              boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Revenue Growth
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <LineChart data={lineChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="uv"
                    stroke={accentColor}
                    strokeWidth={2}
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Bar Chart */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              background: cardGradient,
              boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Monthly Revenue
              </Typography>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={barChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey="revenue"
                    fill={accentColor}
                    radius={[10, 10, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Notifications and Activity Feed */}
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              background: cardGradient,
              boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Notifications
              </Typography>
              <List dense>
                {notifications.map((notification) => (
                  <ListItem key={notification.id} sx={{ mb: 1 }}>
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: accentColor }}>
                        <Notifications sx={{ color: "#fff" }} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={notification.title}
                      secondary={`${notification.description} - ${notification.time}`}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Top Products Table */}
        <Grid item xs={12} md={8}>
          <Card
            sx={{
              background: cardGradient,
              boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
              borderRadius: "12px",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                Top Products
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell align="right">Sales</TableCell>
                      <TableCell align="right">Revenue</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topProducts.map((product) => (
                      <TableRow key={product.id}>
                        <TableCell>{product.name}</TableCell>
                        <TableCell align="right">{product.sales}</TableCell>
                        <TableCell align="right">{product.revenue}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
