import React from "react";
import { Navigate } from "react-router-dom";
import { useDataContext } from "../context/Context"; // Ensure this path is correct
import Sidebar from "./SideMenu/Sidebar";
// import Sidebar from "../sections/Sidebar";

const ProtectedRoute = ({ children }) => {
  const { id } = useDataContext();

  if (!id) {
    console.log("isLoggedIn", id);
    return <Navigate to="/Login" />;
  }

  // Render sidebar and protected content if logged in
  return (
    <div style={{ background: " ", height: "100vh" }}>
      <Sidebar />
      <div style={{ flex: 1, marginTop: "3%" }}>{children}</div>
    </div>
  );
};

export default ProtectedRoute;
