import React from "react";
import { Grid, Paper, Typography, Box } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { formatNumber } from "./formatNumber";

const SummaryPanel = ({ total, totalPrice, uniqueTaggedUserCount }) => {
  const data = [
    {
      label: "Clients",
      value: uniqueTaggedUserCount,
      icon: <PersonIcon />,
      key: 1,
    },
    { label: "Invoices", value: total, icon: <ReceiptIcon />, key: 2 },
    {
      label: "Paid",
      value: `AED ${formatNumber(totalPrice)}`,
      icon: <AccountBalanceWalletIcon />,
      key: 3,
    },
    { label: "Unpaid", value: "$876", icon: <AttachMoneyIcon />, key: 4 },
  ];

  return (
    <Paper
      elevation={2}
      sx={{ padding: 2, borderRadius: 2, background: "#f0f4f8" }}
    >
      <Grid container spacing={4} alignItems="center">
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={3} key={item.key}>
            <Box display="flex" alignItems="center">
              <Box
                sx={{
                  backgroundColor: "#F5F6FA",
                  borderRadius: "50%",
                  padding: 1,
                  marginRight: 2,
                }}
              >
                {item.icon}
              </Box>
              <Box>
                <Typography variant="h6">{item.value}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {item.label}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default SummaryPanel;
