import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import PieChartOutlinedIcon from "@mui/icons-material/PieChartOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";

export const SidebarData = [
  {
    segment: "home",
    title: "Home",
    icon: <HomeOutlinedIcon />,
    path: "/home",
  },
  {
    segment: "audit",
    title: "Audit",
    icon: <AssignmentOutlinedIcon />,
    path: "/audit",
    subNav: [
      {
        segment: "auditList",
        title: "Audit List",
        icon: <ListAltOutlinedIcon />,
        path: "/audit/auditList",
      },
    ],
  },
  {
    segment: "logs",
    title: "Logs",
    icon: <HistoryOutlinedIcon />,

    subNav: [
      {
        segment: "viewLogs",
        title: "View Logs",
        icon: <ListAltOutlinedIcon />,
        path: "/logs/viewLogs",
      },
    ],
  },

  {
    segment: "banks",
    title: "Banks",
    icon: <AccountBalanceOutlinedIcon />,

    subNav: [
      {
        segment: "addBank",
        title: "Add Bank",
        icon: <AddCircleOutlineIcon />,
        path: "/banks/addBank",
      },
      {
        segment: "bankList",
        title: "Bank List",
        icon: <ListAltOutlinedIcon />,
        path: "/banks/bankList",
      },
      {
        segment: "statements",
        title: "Bank Statements",
        icon: <ListAltOutlinedIcon />,
        path: "/banks/bankStatements",
      },
    ],
  },

  {
    segment: "items",
    title: "Items",
    icon: <Inventory2OutlinedIcon />,

    subNav: [
      {
        segment: "add",
        title: "Add Items",
        icon: <AddShoppingCartOutlinedIcon />,
        path: "/items/add",
      },
      {
        segment: "list",
        title: "Item List",
        icon: <ListAltOutlinedIcon />,
        path: "/items/list",
      },
    ],
  },

  // {
  //   segment: "customers",
  //   title: "Customers",
  //   icon: <PeopleOutlineIcon />,

  //   subNav: [
  //     {
  //       segment: "add",
  //       title: "Add Customers",
  //       icon: <AddCircleOutlineIcon />,
  //       path: "/customers/add",
  //     },
  //     {
  //       segment: "list",
  //       title: "Customers List",
  //       icon: <ListAltOutlinedIcon />,
  //       path: "/customers/list",
  //     },
  //   ],
  // },

  // {
  //   segment: "vendors",
  //   title: "Vendors",
  //   icon: <BusinessCenterOutlinedIcon />,

  //   subNav: [
  //     {
  //       segment: "add",
  //       title: "Add Vendors",
  //       icon: <AddCircleOutlineIcon />,
  //       path: "/vendors/add",
  //     },
  //     {
  //       segment: "list",
  //       title: "Vendors List",
  //       icon: <ListAltOutlinedIcon />,
  //       path: "/vendors/list",
  //     },
  //   ],
  // },

  {
    segment: "sales",
    title: "Sales",
    icon: <AttachMoneyOutlinedIcon />,

    subNav: [
      {
        segment: "list",
        title: "Customers  ",
        icon: <ListAltOutlinedIcon />,
        path: "/customers/list",
      },
      {
        segment: "add",
        title: "Add Invoice",
        icon: <CreateOutlinedIcon />,
        path: "/sales/invoice/add",
      },
      {
        segment: "InvoiceList",
        title: "Invoice List",
        icon: <ListAltOutlinedIcon />,
        path: "/sales/invoice/list",
      },
      {
        segment: "Transactionlist",
        title: "Transaction List",
        icon: <ReceiptOutlinedIcon />,
        path: "/sales/transaction/list",
      },
      {
        segment: "PaymentRecieved",
        title: "Payments Received",
        icon: <PaymentOutlinedIcon />,
        path: "/sales/payment/received",
      },
      {
        segment: "CreditNotes",
        title: "Credit Notes",
        icon: <NoteAltOutlinedIcon />,
        path: "/sales/credit-notes",
      },
      {
        segment: "voidlist",
        title: "Void List",
        icon: <ListAltOutlinedIcon />,
        path: "/sales/voided/list",
      },
    ],
  },
  {
    segment: "purchases",
    title: "Purchases",
    icon: <ShoppingCartOutlinedIcon />,

    subNav: [
      {
        segment: "list",
        title: "Vendors",
        icon: <ListAltOutlinedIcon />,
        path: "/vendors/list",
      },
      {
        segment: "AddPurchases",
        title: "Add Purchases",
        icon: <AddShoppingCartOutlinedIcon />,
        path: "/purchases/add",
      },
      {
        segment: "Purchases",
        title: "Purchases List",
        icon: <ListAltOutlinedIcon />,
        path: "/purchases/list",
      },
      {
        segment: "AddExpense",
        title: "Add Expenses",
        icon: <MonetizationOnOutlinedIcon />,
        path: "/expenses/add",
      },
      {
        segment: "Expenselist",
        title: "Expense List",
        icon: <ListAltOutlinedIcon />,
        path: "/expenses/list",
      },
      {
        segment: "PaymentMade",
        title: "Payments Made",
        icon: <PaymentOutlinedIcon />,
        path: "/purchases/payment/made",
      },
      {
        segment: "RecurringBills",
        title: "Recurring Bills",
        icon: <AutorenewOutlinedIcon />,
        path: "/bills/recurring",
      },
    ],
  },

  {
    segment: "reports",
    title: "Reports",
    icon: <AssessmentOutlinedIcon />,

    subNav: [
      {
        segment: "SalesReport",
        title: "Sales Report",
        icon: <BarChartOutlinedIcon />,
        path: "/reports/sales",
      },
      {
        segment: "AdvancedReport",
        title: "Advanced Report",
        icon: <EqualizerOutlinedIcon />,
        path: "/reports/advanced",
      },
      {
        segment: "ProfitAndLoss",
        title: "Profit And Loss",
        icon: <AttachMoneyOutlinedIcon />,
        path: "/reports/profit-loss",
      },
      {
        segment: "BalanceSheet",
        title: "Balance Sheet",
        icon: <PieChartOutlinedIcon />,
        path: "/reports/balance-sheet",
      },
      {
        segment: "TrailBalance",
        title: "Trial Balance",
        icon: <PieChartOutlinedIcon />,
        path: "/reports/trial-balance",
      },
      {
        segment: "CashFlowStatement",
        title: "Cash Flow Statement",
        icon: <TrendingUpOutlinedIcon />,
        path: "/reports/cash-flow",
      },
    ],
  },
  {
    segment: "analytics",
    title: "Analytics",
    icon: <ShowChartOutlinedIcon />,
  },
  {
    segment: "support",
    title: "Support",
    icon: <SupportAgentOutlinedIcon />,
  },
  {
    segment: "security",
    title: "Security",
    icon: <SecurityOutlinedIcon />,
  },
];
